export const helpers = {
    // OPEN POPUP
    openPopup: (el, mainClass = 'mfp-fade', index = 1, onClose = null, onOpen = null) => {
        let config = {
            items: {
                src: el,
                type: 'inline',
                midClick: true,
                closeOnBgClick: false
            },
            removalDelay: 500,
            mainClass,
            callbacks: {},
            key: index
        };

        if(onClose) config.callbacks.close = onClose;
        if(onOpen) config.callbacks.open = onOpen;

        $.magnificPopup.open(config);
    },
    //  CLOSE POPUP
    closePopup: (popupSelector = null, callback = null) => {
        let instance = $.magnificPopup.instance;

        if(popupSelector != null){

            if( typeof instance.items === 'undefined' ) return;

            instance.items.forEach(item => {
                if( item.src == popupSelector ) $.magnificPopup.close();
            });

        } else {
            $.magnificPopup.close();
        }

        if(callback) callback();
    },
    // Get true array/object length
    length: (array) => {
        return Object.keys(array).length;
    },
    // Get image url
    getImageUrl(path) {
        return new URL(path, import.meta.url).href;
    },
    // Parse errors
    parseError(error){
        let _error = '';
        if (Array.isArray(error)) {
            error.forEach( (element, index) => {
                _error += `<p>${element}</p>`;
            });
        } else {
            _error = error;
        }

        return _error;
    },
    // Json stringify
    prepareMessage(message){
        return JSON.stringify(message);
    },
    // Copy to clipboard by input
    copy(inputId){
        let copyText = document.getElementById(inputId);
        copyText.focus();
        copyText.select();

        document.execCommand('copy');
    },

    // Copy value
    copyValue(value) {
        let $temp = $("<input>");
        $("body").append($temp);
        $temp.val( value ).select();
        document.execCommand("copy");
        $temp.remove();
    },

    // Item's name partly
    getPartName(name){
        let explodedName = {
            type: '',
            details: name
        };

        let exploded = name.split('|');
        if( exploded.length > 1 ){
            explodedName.type = exploded[0].trim();
            explodedName.details = exploded[1].trim();
        }

        return explodedName;
    }
};
