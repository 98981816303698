<script setup>
    import { onMounted } from 'vue';

    const props = defineProps(['user', 'messages', 'logo', 'rate']);

    console.log(props.user);

    globalStore.setUser(props.user);
    globalStore.setLogo(props.logo);
    globalStore.setRate(props.rate);

    onMounted( () => {
        props.messages.errors.forEach(element => {
            notificationsStore.add('error', 'Error', element);
        });

        if(props.messages.success != '') {
            notificationsStore.add('success', 'Success', props.messages.success);
        }
    });

    //console.log(globalStore.user);
</script>

<template></template>
