<script setup>
    import { ref, onMounted } from 'vue';

    const history = ref({});    // History

    onMounted(() => {
        // Observer on modal shown
        let modal = document.querySelector('#modal-game_history');
        let observer = new MutationObserver(function (mutations) {
            mutations.forEach((value, index) => {
                if (!value.target.classList.contains('mfp-hide')) {
                    //setTimeout(loadHistory, 200);
                    loadHistory();
                }
            });
        });
        observer.observe(modal, {
            attributes: true
        });
    });

    /** Load history */
    const loadHistory = (url = '/game/history') => {
        $.post(url, {},
            function (data, textStatus, jqXHR) {
                console.log(data);
                history.value = data;
            },
        );
    }

</script>

<template>
    <div id="modal-game_history" class="modal mfp-hide" style="width: 811px;">
        <div class="modal__head">Game history</div>
        <div class="modal__body">
            <div class="table-responsive" style="padding-top: 9px;" cellspacing="0">
                <table class="table table-separate">
                    <thead>
                        <tr>
                            <th>Result</th>
                            <th>Bet</th>
                            <th>Win</th>
                            <th>Date</th>
                            <th>Game ID</th>
                        </tr>
                    </thead>
                    <transition name="fade-fast" mode="out-in">
                        <tbody :key="history.current_page">
                            <tr v-for="(value, index) in history.data">
                                <td class="td-red" v-if="value.user_id != value.win_id">Lose</td>
                                <td class="td-green" v-else>Win</td>
                                <td>${{ value.your_total_bid }}</td>

                                <td v-if="value.user_id != value.win_id">0</td>
                                <td class="td-green" v-else>+{{ (value.total_placed - value.your_total_bid).toFixed(2) }}</td>

                                <td class="td-grey">{{ value.placed_at }}</td>
                                <td>{{ value.game_id }}</td>
                            </tr>
                        </tbody>
                    </transition>

                </table>
            </div>

            <div class="pagination-small">
                <span class="previous" @click="loadHistory(history.prev_page_url ?? history.first_page_url)"></span>
                <span><span class="current">{{ history.current_page ?? 1 }}</span>/{{ history.last_page }}</span>
                <span class="next" @click="loadHistory(history.next_page_url ?? history.last_page_url)"></span>
            </div>
        </div>
    </div>
</template>
