<script setup>
import { ref, reactive, onMounted } from 'vue';
import { helpers } from '../../../../helpers.js';

const globalStore = window.globalStore;

const applied = ref(globalStore.user != null && globalStore.user.temp_promo != null); // Is code applied?
const areDetailsShown = ref(false); // Are details shown?
const choosenSystem = ref(false);   // Choosen system

const promo = ref( globalStore.user == null ? '' : (globalStore.user.temp_promo ?? '') );  // Promocode

/** Copy wallet to clipboard */
const copy = (inputId) => {
    helpers.copy(inputId);
    notificationsStore.add('success', 'Success', 'Copied');
}

/** Available Systems */
const availableSystems = reactive({
    btc: {
        type: 'crypt',
        fullname: 'Bitcoin',
        shortname: 'BTC',
        rate_usd: globalStore.rate.BTC.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/btc.svg', import.meta.url),
        label: "Only deposit over the Bitcoin Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    eth: {
        type: 'crypt',
        fullname: 'Ethereum',
        shortname: 'ETH',
        rate_usd: globalStore.rate.ETH.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/eth.svg', import.meta.url),
        label: "Only deposit over the Ethereum Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: ''
    },
    ltc: {
        type: 'crypt',
        fullname: 'Litecoin',
        shortname: 'LTC',
        rate_usd: globalStore.rate.LTC.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/ltc.svg', import.meta.url),
        label: "Only deposit over the Litecoin Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: ''
    },
    usdt_erc20: {
        type: 'crypt',
        fullname: 'USDT (ERC-20)',
        shortname: 'USDT',
        rate_usd: globalStore.rate.USDT.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/usdt.svg', import.meta.url),
        label: "Only transfer USDT to this wallet using the Ethereum (ERC-20) network. Sending anything else, will result in a permanent loss.",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
        //grow: 1
    },
    usdc: {
        type: 'crypt',
        fullname: 'USDC (ERC-20)',
        shortname: 'USDC',
        rate_usd: globalStore.rate.USDC.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/usdc.svg', import.meta.url),
        label: "Only transfer USDC to this wallet using the Ethereum (ERC-20) network. Sending anything else, will result in a permanent loss.",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
        //grow: 1
    },
    usdt_trc20: {
        type: 'crypt',
        fullname: 'USDT (TRC-20)',
        shortname: 'USDT_TRC20',
        display_shortname: 'USDT',
        rate_usd: globalStore.rate.USDT.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/usdt_trc20.svg', import.meta.url),
        label: "Only transfer USDT to this wallet using the Tron (TRC-20) network. Sending anything else, will result in a permanent loss.",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    xmr: {
        type: 'crypt',
        fullname: 'XMR (Monero)',
        shortname: 'XMR',
        rate_usd: globalStore.rate.XMR.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/xmr.svg', import.meta.url),
        label: "Only deposit over the Monero Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    trx: {
        type: 'crypt',
        fullname: 'TRX (Tron)',
        shortname: 'TRX',
        rate_usd: globalStore.rate.TRX.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/trx.svg', import.meta.url),
        label: "Only deposit over the TRON Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    sol: {
        type: 'crypt',
        fullname: 'Solana',
        shortname: 'SOL',
        rate_usd: globalStore.rate.SOL.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/sol.svg', import.meta.url),
        label: "Only deposit over the Solana Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    doge: {
        type: 'crypt',
        fullname: 'Dogecoin',
        shortname: 'DOGE',
        rate_usd: globalStore.rate.DOGE.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/doge.svg', import.meta.url),
        label: "Only deposit over the Dogecoin Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    bnb: {
        type: 'crypt',
        fullname: 'BNB',
        shortname: 'BNB',
        rate_usd: globalStore.rate.BNB.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/bnb.svg', import.meta.url),
        label: "Only deposit over the BSC Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    dash: {
        type: 'crypt',
        fullname: 'DASH',
        shortname: 'DASH',
        rate_usd: globalStore.rate.DASH.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/dash.svg', import.meta.url),
        label: "Only deposit over the DASH Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    ton: {
        type: 'crypt',
        fullname: 'TON',
        shortname: 'TON',
        rate_usd: globalStore.rate.TON.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/ton.svg', import.meta.url),
        label: "Only deposit over the TON Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },
    bch: {
        type: 'crypt',
        fullname: 'Bitcoin Cash',
        shortname: 'BCH',
        rate_usd: globalStore.rate.BCH.price_usd ?? 0,
        address: '',
        image: new URL('/resources/assets/images/bch.svg', import.meta.url),
        label: "Only deposit over the BCH Network!",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
    },

    skins: {
        type: 'skins',
        fullname: 'CS:GO Skins',
        image: new URL('/resources/assets/images/cs-skins.svg', import.meta.url),
        label: "We do not support TRC 20, BEP 20 or other networks. Make sure to enter an ERC-20 network address for your withdrawal to be processed",
        qr_template: new URL('/resources/assets/images/qr-demo.png', import.meta.url),
        qr: '',
        address: 'Enter your trade link',
    },
});

/** Get systems by type */
const getSystemsByType = (type = 'crypt') => {
    return Object.fromEntries(
        Object.entries(availableSystems).filter(([key, value]) => value.type == type)
    );
};

/** Toggle details by payment type */
const toggle = (key) => {
    if (areDetailsShown.value) return;

    $.post("/wallet/check", {crypt: availableSystems[key].shortname},
        function (data, textStatus, jqXHR) {
            if(data.error != null){
                notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                return;
            }

            availableSystems[key].address = data.address;
            availableSystems[key].qr = data.qr;

            choosenSystem.value = availableSystems[key];
            areDetailsShown.value = true;
        }
    );


};

/** Apply code */
const apply = (status) => {
    if( promo.value.length < 1 ) {
        notificationsStore.add('error', 'Error', 'You need to write code');
        return;
    }

    if( globalStore.user.temp_promo != null ) {
        notificationsStore.add('error', 'Error', 'You need to make deposit to confirm another promo');
        return;
    }

    if(applied.value) return;

    $.post("/promo/check", {promo: promo.value},
        function (data, textStatus, jqXHR) {
            if( data.error != null ) {
                notificationsStore.add('error', 'Error', data.error);
                applied.value = false;
                return;
            }
            applied.value = true;

            notificationsStore.add('success', 'Success', data.success);
        }
    );
}

// Reset Popup Logic on close
onMounted(() => {
    let modal = document.querySelector('#modal-deposit');
    let observer = new MutationObserver(function (mutations) {
        mutations.forEach((value, index) => {
            if (value.target.classList.contains('mfp-hide')) {
                areDetailsShown.value = false;
                choosenSystem.value = false;
                //applied.value = false;
            }
        });
    });
    observer.observe(modal, {
        attributes: true
    });
});

</script>

<template>
    <!-- <div id="modal-deposit" class="modal mfp-hide" style="width: 1043px"> -->
    <div id="modal-deposit" class="modal mfp-hide" :style="[{ 'width': areDetailsShown ? '906px' : '1043px' }]">
        <transition mode="out-in">
            <div v-if="!areDetailsShown">
                <div class="modal__head">
                    <span>Deposit</span>
                </div>
                <div class="modal__body">
                    <div class="input-group">
                        <input type="text" class="input-clear" placeholder="Enter affilate code" v-model="promo">
                        <button class="btn btn-ok" :class="applied ? 'active' : ''" @click="apply(!applied)">{{ applied ?
                            'Applied' : 'Apply' }}</button>
                    </div>

                    <div class="payment-group">
                        <p class="payment-group__name">Cryptocurrencies <span class="bonus_perc" v-if="applied">+10%</span></p>
                        <div class="payments">
                            <div class="payment" v-for="(value, key) in getSystemsByType('crypt')" @click="toggle(key)" :style="[value.grow != null && value.grow == 1 && applied ? {'flex-grow': 1} : {}]">
                                <img :src="value.image" alt="" class="img-adaptive">
                                <div class="payment__details">
                                    <p class="payment__name">{{ value.fullname }}</p>
                                    <span class="payment__rate">1 {{ value.display_shortname != null ? value.display_shortname : value.shortname }} = ~${{ value.rate_usd }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="modal__head">
                    <button class="btn btn-back" @click="areDetailsShown = false">
                        <!-- <img src="/resources/assets/images/icon-back.svg" alt="" class="img-adaptive"> -->
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M18.5355 1.46447C17.0711 2.38419e-07 14.714 2.0621e-07 10 1.52795e-10C5.28595 -2.05905e-07 2.92893 -2.38419e-07 1.46447 1.46447C2.38419e-07 2.92893 2.06212e-07 5.28595 1.54159e-10 10C-2.05903e-07 14.714 -2.38419e-07 17.0711 1.46447 18.5355C2.92893 20 5.28595 20 10 20C14.714 20 17.0711 20 18.5355 18.5355C20 17.0711 20 14.714 20 10C20 5.28595 20 2.92893 18.5355 1.46447ZM12.0303 6.46967C12.3232 6.76256 12.3232 7.23744 12.0303 7.53033L9.56066 10L12.0303 12.4697C12.3232 12.7626 12.3232 13.2374 12.0303 13.5303C11.7374 13.8232 11.2626 13.8232 10.9697 13.5303L7.96967 10.5303C7.82902 10.3897 7.75 10.1989 7.75 10C7.75 9.80109 7.82902 9.61032 7.96967 9.46967L10.9697 6.46967C11.2626 6.17678 11.7374 6.17678 12.0303 6.46967Z"
                                fill="currentColor" fill-opacity="1" />
                        </svg>
                        Back
                    </button>
                </div>
                <div class="modal__body">
                    <div class="deposit">
                        <div class="deposit__code">
                            <img :src="choosenSystem.qr" alt="" class="img-adaptive">
                        </div>

                        <div class="deposit__info">
                            <div class="deposit__type">
                                <img :src="choosenSystem.image" alt="" class="img-adaptive">
                                <span>{{ choosenSystem.fullname }}</span>
                            </div>
                            <p class="deposit__address-label">{{ choosenSystem.fullname }} address</p>
                            <div class="deposit__address">
                                <input type="text" class="input-transparent" id="pay-address" placeholder="Enter your address here.."
                                    :value="choosenSystem.address" readonly>
                                <!-- <img id="copy" src="/resources/assets/images/icon-copy.svg" alt="" class="img-adaptive"> -->
                                <svg @click="copy('pay-address')" id="copy" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="copy-01" clip-path="url(#clip0_112_3816)">
                                        <path id="Icon"
                                            d="M3.75 11.25C3.05109 11.25 2.70163 11.25 2.42597 11.1358C2.05843 10.9836 1.76642 10.6916 1.61418 10.324C1.5 10.0484 1.5 9.69891 1.5 9V3.9C1.5 3.05992 1.5 2.63988 1.66349 2.31901C1.8073 2.03677 2.03677 1.8073 2.31901 1.66349C2.63988 1.5 3.05992 1.5 3.9 1.5H9C9.69891 1.5 10.0484 1.5 10.324 1.61418C10.6916 1.76642 10.9836 2.05843 11.1358 2.42597C11.25 2.70163 11.25 3.05109 11.25 3.75M9.15 16.5H14.1C14.9401 16.5 15.3601 16.5 15.681 16.3365C15.9632 16.1927 16.1927 15.9632 16.3365 15.681C16.5 15.3601 16.5 14.9401 16.5 14.1V9.15C16.5 8.30992 16.5 7.88988 16.3365 7.56901C16.1927 7.28677 15.9632 7.0573 15.681 6.91349C15.3601 6.75 14.9401 6.75 14.1 6.75H9.15C8.30992 6.75 7.88988 6.75 7.56901 6.91349C7.28677 7.0573 7.0573 7.28677 6.91349 7.56901C6.75 7.88988 6.75 8.30992 6.75 9.15V14.1C6.75 14.9401 6.75 15.3601 6.91349 15.681C7.0573 15.9632 7.28677 16.1927 7.56901 16.3365C7.88988 16.5 8.30992 16.5 9.15 16.5Z"
                                            stroke="currentColor" stroke-opacity="1" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_112_3816">
                                            <rect width="18" height="18" fill="currentColor" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p class="deposit__code-note">If you used the code for the deposit, you will receive <span
                                    class="text-green">+10%</span> bonus after the transaction is confirmed</p>
                            <div class="cashout-note">
                                <div class="border"></div>
                                <p>{{ choosenSystem.label }}</p>
                            </div>

                            <div class="cashout-note">
                                <div class="border"></div>
                                <p>Do not make deposits from other casinos! These transactions will be blocked by the AML system (Anti Money Laundering) </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
