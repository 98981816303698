import { defineStore } from 'pinia';

export const useShopItemsStore = defineStore('ShopItemsStore', {
    state: () => ({
        addedItems: new Map(),
        totalSum: 0
    }),

    getters: {
        added: (state) => {
            return state.addedItems;
        }
    },
    actions: {
        add(item){
            this.addedItems.set(item.key, item);
            this.totalSum += parseFloat(item.price);
        },
        remove(key){
            this.totalSum -= parseFloat(this.addedItems.get(key).price);
            if(this.totalSum < 0) this.totalSum = 0;
            this.addedItems.delete(key);
        }
    },
});
