<script setup>
    import { ref, onMounted } from 'vue';
    import { helpers } from '../../../../helpers.js';
    import Dropdown from '../../Dropdown.vue';
    import { useWebSocketStore } from '../../../stores/WebSocket';
    import { useGameStore } from '../../../stores/GameStore';

    const webSocketStore = useWebSocketStore();
    const gameStore = useGameStore();

    const addedItems = ref([]); // Added Items
    const totalSum = ref(0);  // Total price of added items
    const allItems = ref({});  // All items
    const searchName = ref('');    // Name to search
    const steamId = ref('');   // Steam ID

    let priceRangeType = 'all';   // All (Default Sorting)

    // Dropdowns
    const dropdownPriceRange = [
        {value: 'all', label: 'All prices'},
        {value: '0-50', label: '$0 - $50'},
        {value: '50-200', label: '$50 - $200'},
        {value: '200-800', label: '$200 - $800'},
        {value: '800-1500', label: '$800 - $1500'},
        {value: '1500', label: '$1500+'},
    ];

    // Add / remove item
    const toggle = (index, event) => {
        let parentSelector = event.target.closest('.shop-item');
        let item = {
            key: index,
            item_id: allItems.value[index].id,
            image: parentSelector.querySelector('img').getAttribute('src'),
            price: parentSelector.getAttribute('data-price'),
            type: parentSelector.getAttribute('data-type'),
            name: parentSelector.querySelector('.shop-item__name').textContent,
            color: allItems.value[index].color
        };

        //addedItems.value.set(index, item);
        addedItems.value.push(item);
        totalSum.value += parseFloat(item.price);
    }

    /* Remote item from added */
    const del = (index) => {
        totalSum.value -= parseFloat(addedItems.value[index].price);
        if(totalSum.value <= 0) totalSum.value = 0;
        //addedItems.value.delete(index);
        addedItems.value = addedItems.value.filter( (value, key) => key !== index );
    }

    // Reset Popup Logic on close
    onMounted(() => {
        let modal = document.querySelector('#modal-admin');
        let observer = new MutationObserver(function (mutations) {
            mutations.forEach((value, index) => {
                if (value.target.classList.contains('mfp-hide')) {
                    addedItems.value = []; // Added Items
                    totalSum.value = 0;  // Total price of added items
                    steamId.value = ''; // Reset STEAM ID
                    searchName.value = ''; // Reset item's name if search performed
                    priceRangeType = 'all';
                    //$('#admin-price-dropdown .dropdown__selected').html( dropdownPriceRange[0].label );
                    document.querySelector('#admin-price-dropdown .dropdown__selected').innerHTML = dropdownPriceRange[0].label;
                } else {
                    loadAllItems();
                }
            });
        });
        observer.observe(modal, {
            attributes: true
        });
    });

    // Load items
    const loadAllItems = () => {
        $.post("/items/all", {},
            function (data, textStatus, jqXHR) {
                if(data.error != null){
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                    return;
                }

                allItems.value = data;
            },
        );
    }

    // Search items
    let waiting = false;
    const search = () => {
        if(waiting) return;
        waiting = true;
        setTimeout(_search, 300);
    }

    // Sort by price range
    const sortByPriceRange = (index) => {
        priceRangeType = dropdownPriceRange[index].value;
        if(priceRangeType == null) {
            notificationsStore.add('error', 'Error', `Can't identify dropdown's value`);
            return;
        }

        _search();
    }

    /** Place bet */
    const placeBet = () => {
        console.log(addedItems.value);
        if(addedItems.value.length == 0) {
            notificationsStore.add('error', 'Error', 'You need to choose at least 1 item');
            return;
        }
        if(webSocketStore.socket == null) {
            notificationsStore.add('error', 'Error', 'You are not connected to websocket. Refresh page!');
            return;
        }

        let items = [];
        addedItems.value.forEach( (value, key) => items.push(value.item_id) );

        webSocketStore.socket.send( helpers.prepareMessage({
            action: 'place_admin_bet',
            game_id: gameStore.game.game.id,
            steam_id: steamId.value,
            items
        }) );

        addedItems.value = [];  // Reset items
        steamId.value = ''; // Reset STEAM ID
    }

    function _search() {
        $.post("/shop/search", {name: searchName.value, price_type: 'highest', price_range_type: priceRangeType},
            function (data, textStatus, jqXHR) {
                console.log(data);

                waiting = false;

                if(data.error != null){
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                    return;
                }

                allItems.value = data;
            }
        );
    }
</script>

<template>
    <!-- Modal ['Admin Tool'] -->
    <div id="modal-admin" class="modal mfp-hide" style="width: 1043px;">
        <div class="modal__head">
            <div class="admin-inputs">
                <input type="text" class="input-clear" placeholder="Steam id" v-model="steamId">
                <div class="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                        <path
                            d="M14.875 15.375L10.6251 11.125M12.0417 7.58333C12.0417 10.3217 9.82175 12.5417 7.08333 12.5417C4.34492 12.5417 2.125 10.3217 2.125 7.58333C2.125 4.84492 4.34492 2.625 7.08333 2.625C9.82175 2.625 12.0417 4.84492 12.0417 7.58333Z"
                            stroke="white" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <input type="text" class="input-transparent" placeholder="Search" v-model="searchName" @input="search">
                </div>
            </div>
        </div>
        <div class="modal__body">
            <Dropdown :list="dropdownPriceRange" :index="1" :content_width="'199px'" :arrow_color="'grey'" :extra_content_style="{'right': 'auto'}" :id="'admin-price-dropdown'" v-on:onSelect="sortByPriceRange"></Dropdown>
            <div class="admin skins">
                <div class="items">
                    <div class="shop-item shop-item_default" @click="toggle(key, $event)" :data-price='value.price_usd' :data-drop_id="value.id" v-for="(value, key) in allItems" >
                        <div class="shop-item__body">
                            <span class="shop-item__price" :style="{color: value.color}">${{value.price_usd}}</span>
                            <img :src="value.image" alt="" class="img-adaptive shop-item__avatar">
                            <p class="shop-item__name">{{ value.name }}</p>
                            <div class="blur"  :style="[{'background': `linear-gradient(359deg, ${value.color} -292.06%, rgba(255, 0, 0, 0) 86.09%)`}, {'border-bottom': `3.694px solid ${value.color}`}]"></div>
                        </div>
                    </div>
                </div>

                <div class="skins__stats">
                    <span>Total selected: <span class="skins__stats-value">{{ addedItems.length }} items</span></span>
                    <span>Total amount: <span class="skins__stats-value">${{ totalSum.toFixed(2) }}</span></span>
                </div>

                <div class="skins__actions">
                    <button class="btn btn-orange" @click="helpers.closePopup()">Cancel</button>
                    <button class="btn btn-gradient" @click="placeBet">Rate</button>
                </div>

                <transition>
                    <div class="skins__choosen" v-if="addedItems.length > 0">
                        <transition-group>
                            <div class="shop-item shop-item_default" :key="key" @click="del(key, $event)" v-for="(value, key) in addedItems">
                                <div class="shop-item__body">
                                    <span class="shop-item__price">${{ value.price }}</span>
                                    <img :src="value.image" alt="" class="img-adaptive shop-item__avatar">
                                    <p class="shop-item__name">{{ value.name }}</p>
                                    <div class="blur"  :style="[{'background': `linear-gradient(359deg, ${value.color} -292.06%, rgba(255, 0, 0, 0) 86.09%)`}, {'border-bottom': `3.694px solid ${value.color}`}]"></div>
                                </div>
                            </div>
                        </transition-group>

                    </div>
                </transition>

            </div>

        </div>
    </div>
</template>
