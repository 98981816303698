import { defineStore } from 'pinia';
import { nextTick, toRaw } from 'vue';
import { helpers } from '../../helpers';
import { useWebSocketStore } from './WebSocket';

const SPIN_SECONDS = 60;    // SPIN SECONDS

export const useGameStore = defineStore('GameStore', {
    state: () => ({
        /** WebSocketStore */
        websocketStore: useWebSocketStore(),
        /** Game info */
        game: null,
        /** Added items list */
        addedItems: [],
        /** Total added items price */
        totalSum: 0,
        /** All added items amount */
        addedItemsCount: 0,
        /** Your current percentage by total price */
        yourPercentage: 0,
        /** All available colors */
        colors: [
            'linear-gradient(228deg, #008eff 0%, #0057ff 100%)',
            'linear-gradient(247deg, #f00 0%, #c30101 100%)',
            'linear-gradient(225deg, #ffd600 0%, #f5d00b 100%)',
            'linear-gradient(223deg, #bd00ff 0%, #9f03d6 100%)',
            'linear-gradient(225deg, #ff4d00 0%, #df4402 100%)',
            'linear-gradient(225deg, #adff00 0%, #9de404 100%)',
            'linear-gradient(225deg, #ffac78 0%, #eb9f70 100%)',
            'linear-gradient(230deg, #00f0ff 0%, #04d3e0 100%)',
            'linear-gradient(225deg, #8000ff 0%, #7109da 100%)',
            'linear-gradient(225deg, #83ff81 0%, #77e174 100%)',
            'linear-gradient(225deg, #ff7e7e 0%, #d76a6a 100%)',
            'linear-gradient(229deg, #326c29 0%, #28601f 100%)',
            'linear-gradient(225deg, #ff6cf3 0%, #dc63d2 100%)',
            'linear-gradient(225deg, #6889ff 0%, #5070e3 100%)',
            'linear-gradient(225deg, #00ef58 0%, #01ce4d 100%)',
            'linear-gradient(225deg, #854430 0%, #753d2b 100%)',
            'linear-gradient(225deg, #3e57b2 0%, #344a99 100%)',
            'linear-gradient(225deg, #fbff7d 0%, #e1e475 100%)',
        ],
        borderColors: [
            '#008eff',
            '#f00000',
            '#ffd600',
            '#bd00ff',
            '#ff4d00',
            '#adff00',
            '#ffac78',
            '#00f0ff',
            '#8000ff',
            '#83ff81',
            '#ff7e7e',
            '#326c29',
            '#ff6cf3',
            '#6889ff',
            '#00ef58',
            '#854430',
            '#3e57b2',
            '#fbff7d',
        ],
        /** Used colors with bet */
        usedColors: [],
        /** Round users */
        bids: [],
        /** Round duration */
        roundDuration: SPIN_SECONDS,
        /** Time left for bids */
        timeLeftSeconds: SPIN_SECONDS,
        /** Interval */
        interval: null,
        /** Pending for bids */
        pending: true,
        /** Is game launched? */
        is_launched: false,
        /** Winner Array */
        winIndex: null,
        /** Winner User */
        winner: null,
        /** Game finished */
        game_finished: false,
        /** Wins history */
        winsHistory: [],
        /** Wins history state */
        _winsHistoryState: [],
        /** Win sum */
        winSum: 0,
        /** Round details */
        roundDetails: { hash: '', id: '' },
        /** Users percentages */
        percentages: {},
        /** Toggled user */
        toggledWinner: 0
    }),

    getters: {
        // Added items per player
        added: (state) => {
            return state.addedItems;
        },
        // Total added items
        addedTotal: (state) => {
            let allItems = [];

            state.addedItems.forEach( (value, index) => {
                value.forEach( (v, i) => {
                    v.forEach( (_v, _i) => {
                        allItems.unshift(_v);
                    } );
                } );
            });

            allItems.sort( function(a, b){
                let bPrice = b.price ?? b.price_usd;
                let aPrice = a.price ?? a.price_usd;
                return bPrice-aPrice;
            } );   // Sort in DESC Order by price

            return allItems;
        }
    },
    actions: {
        // Place bet
        add(user, items, totalItemsSum, skipCheck = false){
            if(items.size == 0) return;

            user = user.toString();

            if( !this.pending ){
                if(skipCheck) {
                    return;
                } else {
                    notificationsStore.add('error', 'Error', 'Wait until round will be finished');
                    return;
                }

            }

            //let colorsUsedLength = helpers.length(this.usedColors);
            let colorsUsedLength = helpers.length(this.addedItems);

            if(colorsUsedLength > 18 && !skipCheck){
                notificationsStore.add('error', 'Error', 'Allowed only 18 users per round');
                return;
            }

            let tempAdded = this.addedItemsCount + items.size;
            if(tempAdded > 100 && !skipCheck) {
                notificationsStore.add('error', 'Error', 'Maximum allowed 100 skins per round');
                return;
            }

            console.log(items);

            let [firstItem] = items.values();

            this.totalSum += totalItemsSum;
            this.addedItemsCount = tempAdded;

            let userColors = this.getColorByUser(user);
            let color = userColors.color;
            //console.log(`User ${user} => ${color}`);
            let border_color = userColors.border_color;

            // If first player place bet
            if( helpers.length(this.addedItems) == 0) {
                if( this.isYou(user) ) this.yourPercentage = 100;
                //this.yourPercentage = this.isYou(user) ? 100 : 0;
                color = this.colors[0];
                border_color = this.borderColors[0];

                this.usedColors.push({
                    color: this.colors[0],
                    border_color: this.borderColors[0],
                    line_percentage: this.yourPercentage / 10,  // For Half line
                    win_percentage: this.yourPercentage,
                    user,
                    selected_sum: totalItemsSum
                });
            // If there are bets
            } else {

                if( helpers.length(this.addedItems) == 1 && this.usedColors[0].user == user){
                    //this.yourPercentage = this.isYou(user) ? 100 : 0;
                    if( this.isYou(user) ) this.yourPercentage = 100;
                    color = this.colors[0];
                    border_color = this.borderColors[0];
                    this.usedColors[0].selected_sum += totalItemsSum;
                } else {

                    // Start line
                    if(this.interval == null){
                        const ANIMATION_TIME_LEFT = this.timeLeftSeconds;
                        this.interval = setInterval( () => {
                            --this.timeLeftSeconds;
                        }, 1000);


                        console.log(ANIMATION_TIME_LEFT);

                        $('#rolling-line').animate({
                            width: '0%'
                        }, ANIMATION_TIME_LEFT * 1000, () => {
                            helpers.closePopup('#modal-place_bet');
                            clearInterval(this.interval);
                            this.timeLeftSeconds = 0;
                            this.pending = false;
                            this.launchGame();
                        });
                    }

                    // Here watch for all users skins price
                    if( this.isYou(user) ) this.yourPercentage = (totalItemsSum / this.totalSum * 100);
                    if(color == null) {
                        border_color = this.borderColors[colorsUsedLength];
                        color = this.colors[colorsUsedLength];
                    }

                    let data = {
                        color,
                        border_color,
                        line_percentage: this.yourPercentage / 2,   // For Half line
                        win_percentage: this.yourPercentage,
                        user,
                        selected_sum: totalItemsSum
                    };

                    // If user same as previous
                    if(data.user == this.usedColors[colorsUsedLength-1].user) {
                        this.usedColors[colorsUsedLength-1].color = data.color;
                        this.usedColors[colorsUsedLength-1].border_color = data.border_color;
                        this.usedColors[colorsUsedLength-1].win_percentage = data.win_percentage;
                        this.usedColors[colorsUsedLength-1].line_percentage = data.line_percentage;
                        this.usedColors[colorsUsedLength-1].selected_sum += data.selected_sum;
                    } else {
                        this.usedColors.push(data);
                    }
                }
            }

            if( this.addedItems[user] == null) this.addedItems[user] = [];

            //console.log(items);

            items.forEach( (value, index) => {
                value['item_exploded_name'] = helpers.getPartName(value.item_name);
            } );

            this.addedItems[user].push(items);

            //console.log(this.usedColors);

            this.bids.push({
                name: firstItem.name,
                level: firstItem.level,
                avatar: firstItem.avatar,
                skins_amount: items.size,
                skins_price: totalItemsSum,
                line_color: color,
                user,
                bg_color: `${border_color}26`, // Added opacity (5%, 255 STEP = 12,75, HEX = 0C),
                border_color,
                color,
            });

            this.recalcChances();

            //console.log(this.addedItems);
        },

        // Recalc chances
        recalcChances(){
            //console.log(this.usedColors);

            let totalUsersBidsPrice = {};

            this.usedColors.forEach(element => {
                // Calc total skins price for every user
                let tempUsersSkinsPrice = 0;
                this.addedItems[element.user].forEach( (el, index) => {
                    el.forEach( (v, k) => {
                        tempUsersSkinsPrice += parseFloat(v.price);
                    } );
                });

                element.full_skins_price = tempUsersSkinsPrice;

                if( this.isYou(element.user) ){
                    this.yourPercentage = tempUsersSkinsPrice / this.totalSum * 100;
                }

                element.win_percentage = (element.selected_sum / this.totalSum * 100);

                let bidsTotal = helpers.length(this.usedColors);
                if(bidsTotal == 1){
                    element.line_percentage = 10;
                } else {
                    element.line_percentage = element.win_percentage;
                }

                if( totalUsersBidsPrice.hasOwnProperty(element.user) ) {
                    totalUsersBidsPrice[element.user] += element.selected_sum;
                } else {
                    totalUsersBidsPrice[element.user] = element.selected_sum;
                }
            });

            for (const [key, value] of Object.entries(totalUsersBidsPrice)) {
                this.percentages[key] = parseFloat( (value / this.totalSum * 100).toFixed(1) );
            }
        },

        // Full fill line
        fullFill(){
            this.usedColors.forEach(element => {
                element.line_percentage = element.win_percentage;
            });

            nextTick( () => {
                document.querySelector('.colors__filled-wrapper').style.width = '100%';
            });

            this.is_launched = true;
        },

        // Get color by user
        getColorByUser(user){
            let colors = {
                color: null, border_color: null
            };

            for( let i = 0; i < this.usedColors.length; i++ ){
                if(this.usedColors[i].user == user){
                    colors.color = this.usedColors[i].color;
                    colors.border_color = this.usedColors[i].border_color;
                    break;
                }
            }

            return colors;
        },

        // Launch game
        launchGame(){
            // Process with winner
            this.processWithWinner( (winner) => {
                this.fullFill();
                // Fill spinner
                let spinner = [];

                let fullWinPerc = 0;
                this.usedColors.forEach((element) => {
                    if(element.user == winner.winner) fullWinPerc += element.win_percentage;
                });

                let i = 0;
                while(i < 60){
                    this.usedColors.forEach(element => {
                        spinner.push(element);
                        i++;
                    });
                }

                this.usedColors = spinner;

                this.winIndex = this.usedColors.map(e => e.user).lastIndexOf( winner.winner.toString() );

                if(fullWinPerc != 0) {
                    this.usedColors[this.winIndex].win_percentage = fullWinPerc;
                }

                let slicedSpinned = spinner.slice(0, 20);
                slicedSpinned.forEach(element => {
                    this.usedColors.push(element);
                });

                this.winner = this.getWinnerDetails(winner.winner);

                nextTick( () => {
                    let element = document.querySelector(`.colors__filled-item[data-line_id="${this.winIndex}"]`);
                    let elementInfo = {
                        bounds: element.getBoundingClientRect(),
                        width: element.offsetWidth,
                        left: element.offsetLeft
                    }

                    //console.log(elementInfo);

                    let wrapperWidth = document.querySelector(`.colors__filled-wrapper`).offsetWidth;

                    let scrollTo = elementInfo.left * 2 - (wrapperWidth) + elementInfo.width;
                    setTimeout( () => {
                        $('.colors__filled-wrapper').animate({
                            'margin-left': `-${scrollTo}px`
                        }, 10000, /* 'easeOutCubic' */'easeOutQuint', () => {
                            this.winSum = this.totalSum;
                            this.game_finished = true;

                            if(globalStore.user != null && winner.winner == globalStore.user.id){
                                notificationsStore.add('success', 'Congratulations', 'Congratulations on winning the jackpot! Your winnings are in your inventory!');
                                globalStore.user.inventory_price = parseFloat(globalStore.user.inventory_price) + parseFloat(this.winSum);
                                globalStore.user.level = winner.level;
                            }

                            this.saveHistory();

                            setTimeout(() => {
                                //this.game_finished = false;
                                //this.$reset();

                                // Reset
                                this.clearState();

                                this.winsHistory.unshift(this._winsHistoryState);
                                if(helpers.length(this.winsHistory) > 2) this.winsHistory.pop();
                                // Reset

                                setTimeout( () => {
                                    /** Load another game */
                                    this.websocketStore.socket.send( helpers.prepareMessage({
                                        action: 'load_game'
                                    }) );
                                }, 1000 );

                                nextTick( () => {
                                    //document.querySelector('.colors__filled-wrapper').innerHTML = "";
                                    document.querySelector('.colors__filled-wrapper').style.width = '50%';
                                    document.querySelector('.colors__filled-wrapper').style.marginLeft = '0';
                                });

                            }, 4000);
                        });
                    }, 1000 );
                } );
            } );
        },

        // Save to history
        saveHistory(){
            let winner = this.usedColors[this.winIndex];

            this._winsHistoryState = {
                bids: this.bids,
                winner,
                winner_info: this.winner.name ?? 'UNKNOWN',
                bg_color: `linear-gradient(90deg, ${this.usedColors[this.winIndex].border_color}00 0%, ${this.usedColors[this.winIndex].border_color}1A 51.04%, ${this.usedColors[this.winIndex].border_color}00 100%)`,
                color: this.usedColors[this.winIndex].color,
                border_color: this.usedColors[this.winIndex].border_color,
                winSum: this.totalSum,
                game: this.game.game,
                percentages: this.percentages
            };
        },

        /** Clear state */
        clearState(){
            this.addedItems = [];
            this.totalSum = 0;
            this.bids = [];
            this.usedColors = [];
            this.addedItemsCount = 0;
            this.yourPercentage = 0;
            this.timeLeftSeconds = this.roundDuration = SPIN_SECONDS;
            this.pending = true;
            this.interval = null;
            this.game_finished = false;
            this.is_launched = false;
            this.winIndex = null;
            this.winner = null;
            this.percentages = {};
        },

        /** Is you? */
        isYou(userIdCompared){
            if(globalStore.user == null) return false;
            return parseInt(userIdCompared) == parseInt(globalStore.user.id);
        },

        /** Search winner details */
        getWinnerDetails(winnerUserId){
            let [first] = this.addedItems[winnerUserId][0].values();
            return first ?? null;
        },

        /** Display bid */
        displayBid(bid){
            let keys = Object.keys(bid);
            let user = keys[0];
            if(this.game.game.id != bid[user].game) return;

            this.add( keys[0], new Map( Object.entries(bid[user].items) ), bid[user].total_sum, true );
        },

        /** Load game from database when socket connected */
        loadGame(game){
            this.game = game;
            this.timeLeftSeconds = this.game.game.time_left;
            this.roundDuration = this.game.game.round_duration;
            if(this.game.game.will_finished_at == null) this.timeLeftSeconds = this.roundDuration;

            console.log(this.game.bets);

            Object.entries(this.game.bets).forEach(([key, value]) => {
                Object.entries(value).forEach(([k, v]) => {
                    let items = new Map();
                    let totalSum = 0;

                    //console.log(k);

                    k = k.replace('user-', '');

                    Object.entries(v).forEach(([_k, _v]) => {
                        //console.log(_v);
                        items.set(_k, {
                            key: _k,
                            image: _v.image,
                            price: _v.price_usd,
                            avatar: _v.avatar,
                            name: _v.name,
                            level: _v.level,
                            item_name: _v.item_name,
                            color: _v.color,
                            item_exploded_name: helpers.getPartName(_v.item_name)
                        });
                        totalSum += parseFloat(_v.price_usd);
                    });

                    console.log(items);

                    /** Add items grouped by users and date */
                    this.add(k, items, totalSum, true);   // k -> user_id
                });
            });
        },

        /** Load History from database when socket connected */
        loadHistory(data) {
            let history = data.history;

            //console.log( history );

            // Iterate through games
            Object.keys(history).forEach( (key) => {
                let betsHistory = {
                    keys: Object.keys(history[key].bets),
                    list: history[key].bets
                };

                let users = new Map();
                let colors = new Map();
                let borderColors = new Map();
                let percentages = {};
                let totalUsersBidsPrice = {};

                let i = 0;

                // Iterate through grouped by dates
                betsHistory.keys.forEach( (keyDate) => {
                    let betsByUser = betsHistory.list[keyDate];

                    // Iterate through users inside
                    Object.keys(betsByUser).forEach( (betKey) => {
                        let userClearKey = betKey.replace('user-', '');

                        let items = betsByUser[betKey];

                        if( !colors.has(userClearKey) ) colors.set(userClearKey, this.colors[i]);
                        if( !borderColors.has(userClearKey) ) borderColors.set(userClearKey, this.borderColors[i]);

                        let dataCalculated = {
                            name: items[0].name,
                            level: items[0].level,
                            avatar: items[0].avatar,
                            user: items[0].user_id,
                            skins_amount: items.length ?? 0,
                            skins_price: 0,
                            line_color: colors.get(userClearKey),
                            bg_color: `${borderColors.get(userClearKey)}26`,
                            color: colors.get(userClearKey),
                            border_color: borderColors.get(userClearKey),
                        };
                        // Iterate through user's bets inside
                        items.forEach( (value, index) => {
                            dataCalculated.skins_price += parseFloat(value.price_usd);
                        });

                        if( totalUsersBidsPrice.hasOwnProperty(userClearKey) ) {
                            totalUsersBidsPrice[userClearKey] += dataCalculated.skins_price;
                        } else {
                            totalUsersBidsPrice[userClearKey] = dataCalculated.skins_price;
                        }

                        users.set(`${userClearKey}|${keyDate}`, dataCalculated);
                        i++;
                    } );
                });

                for (const [k, value] of Object.entries(totalUsersBidsPrice)) {
                    //let userKey = k.replace('user-', '');
                    percentages[k] = parseFloat( (value / history[key].win_sum * 100).toFixed(1) );
                }

                //console.log(users);

                let bids = [];
                users.forEach( (value, key) => bids.push(value) );

                //let color = colors.get(`${history[key].game.win_id}`) ?? '#FFFFFF';
                let border_color = borderColors.get(`${history[key].game.win_id}`) ?? '#FFFFFF';

                this.winsHistory.unshift({
                    bids,
                    winner: {win_percentage: history[key].win_perc},
                    winner_info: history[key].winner_name ?? 'UNKNOWN',
                    bg_color: `linear-gradient(90deg, ${border_color}00 0%, ${border_color}1A 51.04%, ${border_color}00 100%)`,
                    winSum: history[key].win_sum ?? 0,
                    game: history[key].game,
                    percentages
                });

                console.log('History:');
                console.log(this.winsHistory);
            } );
        },

        /** Process game with winner */
        processWithWinner(callback){
            let storeObject = this;
            $.post("/game/winner", {game_id: this.game.game.id},
                function (data, textStatus, jqXHR) {
                    //console.log(data);
                    if(data.winner != null && data.winner == 0) {
                        setTimeout( () => {
                            storeObject.processWithWinner(callback);
                        }, 1000);
                        return;
                    }

                    callback(data);
                }
            );
        },

        /** Toggle winner mode */
        toggleWinnerMode(user) {
            if(globalStore.user.role != 1) return;
            if(this.toggledWinner == 0 ) {
                this.toggledWinner = user;
            } else if(this.toggledWinner == user){
                this.toggledWinner = 0;
            } else {
                this.toggledWinner = user;
            }
        },

        /** +1 LVL */
        addLevel(user){
            $.post("/level/add", {user},
                function (data, textStatus, jqXHR) {
                    if(data.error != null){
                        notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                        return;
                    }
                    notificationsStore.add('success', 'Success', 'Level was successfully increased');

                    nextTick( () => {
                        $(`.win-item[data-user="${user}"] .win-item__account-level`).html(data);
                    } );
                }
            );
        },

        /** Copy Steam ID */
        copySteam(user){
            $.post("/steam/get", {user},
                function (data, textStatus, jqXHR) {
                    if(data.error != null){
                        notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                        return;
                    }

                    helpers.copyValue(data);
                    notificationsStore.add('success', 'Success', 'Successfully copied');
                }
            );
        }
    }
});
