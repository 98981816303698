<script setup>
    import { ref } from 'vue';

    const props = defineProps(['items']);
    const shownItems = ref([]);

    const toggle = (item) => {
        if( shownItems.value.includes(item) ) {
            const index = shownItems.value.indexOf(item);
            shownItems.value.splice(index, 1);
        } else {
            shownItems.value.push(item);
        }
    };
    const beforeEnter = (el) => el.style.height = '0';
    const enter = (el) => el.style.height = el.scrollHeight + 'px';
    const beforeLeave = (el) => el.style.height = el.scrollHeight + 'px';
    const leave = (el) => el.style.height = '0';
</script>

<template>

    <div class="accordion" v-for="(value, index) in items">
        <div class="accordion__header" @click="toggle(index)">
            {{ value.question }}
            <div class="accordion__icon" :class="{rotate: shownItems.includes(index) }"></div>
        </div>
        <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
            <div class="accordion__body" v-show="shownItems.includes(index)">
                <div class="accordion__body-inner" v-html="value.answer">
                </div>
            </div>
        </transition>
    </div>

</template>
