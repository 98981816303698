<script setup>
    import { ref, nextTick, defineEmits } from 'vue';
    import { useDropdownStore } from '../stores/DropdownStore';

    const dropdownStore = useDropdownStore();

    const props = defineProps(['list', 'extra_style', 'arrow_color', 'index', 'content_width', 'extra_content_style', 'id']);
    const emit = defineEmits(['onSelect']);

    const selectedItem = ref(props.list[0] ?? 'Choose..');
    const contentWidth = props.content_width ?? '199px';

    const contentStyle = {
        'width': contentWidth
    }

    if(props.extra_content_style != null) Object.assign(contentStyle, props.extra_content_style);


    const select = (index) => {
        selectedItem.value = props.list[index];
        change();
        emit('onSelect', [index]);
    }

    function change() {
        if(dropdownStore.isOpened == props.index){
            dropdownStore.change(false);
        } else {
            dropdownStore.change(props.index);
            // Fix on mobiles
            let viewportOffset =  document.querySelector('.dropdown__selected').getBoundingClientRect();
            let dropdownContentWidth = props.content_width.replace(/[^0-9]/g,"");

            console.log(dropdownContentWidth);

            if(viewportOffset.x - dropdownContentWidth < 0 ){

                nextTick(() =>{
                    document.querySelector('.dropdown').style.position = 'static';
                    document.querySelector('.dropdown__content').style.left = '20px';
                    document.querySelector('.dropdown__content').style.maxWidth = '100%';
                });

            }

        }
    }

</script>

<template>
    <div class="dropdown" :class="arrow_color == 'grey' ? 'dropdown_grey' : ''" :id="id">
        <div class="dropdown__selected" @click="change" :style="extra_style ?? ''">{{ typeof selectedItem === 'object' ? selectedItem.label : selectedItem }}</div>
        <transition>
                <div class="dropdown__content" v-if="dropdownStore.isOpened == index" :style="contentStyle">
                <ul class="ul-clear">
                    <li v-for="(value, key) in list" @click="select(key)" :data-value="typeof value === 'object' ? value.value : value">{{typeof value === 'object' ? value.label : value}}</li>
                </ul>
            </div>
        </transition>
    </div>
</template>
