import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('GlobalStore', {
    state: () => ({
        user: null,
        logo: '',
        rate: null,
        inventory: null
    }),
    actions: {
        // Set user
        setUser(user){
            this.user = user;
        },
        // Set logo
        setLogo(logo){
            this.logo = logo;
        },
        // Set rate
        setRate(rate){
            this.rate = rate;
        },
        // Set inventory
        setInventory(inventory){
            this.inventory = inventory;
        }
    },
});
