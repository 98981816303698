import { defineStore } from 'pinia';

export const useDropdownStore = defineStore('DropdownStore', {
    state: () => ({
        opened: false,
    }),

    getters: {
        isOpened: (state) => {
            return state.opened;
        }
    },
    actions: {
        change(status){
            this.opened = status;
        },
    },
});
