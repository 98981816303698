<script setup>
    import { reactive, ref, defineExpose, nextTick } from 'vue';
    import { useShopItemsStore } from '../stores/ShopItemsStore';
    import { helpers } from '../../helpers';

    const props = defineProps(['items']);

    const shopItemsStore = useShopItemsStore();

    const selectedAll = ref(false);

    // Add / remove item
    const toggle = (index, event) => {
        if( shopItemsStore.added.has(index) ){
            shopItemsStore.remove(index);
        } else {
            let parentSelector = event.target.closest('.shop-item');

            let item = {
                key: index,
                id: parentSelector.getAttribute('data-id'),
                image: parentSelector.querySelector('img').getAttribute('src'),
                price: parentSelector.getAttribute('data-price'),
                type: parentSelector.getAttribute('data-type'),
                color: parentSelector.getAttribute('data-color'),
                details_name: parentSelector.querySelector('.shop-item__details-name').textContent,
                details_type: parentSelector.querySelector('.shop-item__details-type').textContent
            };

            if( parentSelector.getAttribute('data-drop_id') ) item.drop_id = parentSelector.getAttribute('data-drop_id');

            shopItemsStore.add(item);
        }
    }

    function chooseAll(){
        nextTick( () => {
            if(!selectedAll.value) {
                document.querySelectorAll(".shop-item:not(.active)").forEach( (item) => {
                    item.click();
                } );
            } else {
                document.querySelectorAll('.shop-item.active').forEach( (item) => {
                    item.click();
                } );
            }

            selectedAll.value = !selectedAll.value;
        } );
    };

    defineExpose({chooseAll});
</script>

<template>
    <transition-group name="fade" mode="out-in">
        <div class="shop-item" :key='key' @click="toggle(key, $event)" :class="[shopItemsStore.added.has(key) ? 'active' : '', 'shop-item_'+value.type]" :data-type='value.type' :data-color='value.color' :data-price='value.price_usd' :data-id='value.id' :data-drop_id="value.drop_id" v-for="(value, key) in items" >
            <div class="shop-item__body">
                <div class="rectangle" v-if="value.is_square_needed"></div>
                <span class="shop-item__price" :style="{color: value.color}">${{value.price_usd}}</span>
                <img loading="lazy" :src='value.image' alt="" class="img-adaptive shop-item__avatar">
                <div class="blur" :style="[{'background': `linear-gradient(359deg, ${value.color} -292.06%, rgba(255, 0, 0, 0) 86.09%)`}, {'border-bottom': `3.694px solid ${value.color}`}]"></div>
            </div>

            <div class="shop-item__details">
                <p class="shop-item__details-name">{{ value.part_name.type }}</p>
                <span class="shop-item__details-type" v-if="value.part_name.details.length > 0">{{ value.part_name.details }}</span>
            </div>
        </div>
    </transition-group>

</template>
