<script setup>
    import { ref, onMounted, nextTick } from 'vue';

    const cases = ref(null);    // Cases
    const canOpen = ref(false); // Can open cases?
    const timeLeft = ref('00:00:00');   // Time left to next opening
    const isCaseViewed = ref(false);    // Is case viewed?
    const viewedCase = ref(null); // Info about the case
    const itemsFilled = ref([]); // Filled items in roulette
    const startLinePosition = ref(-71); // Start line pos
    const lastWinItemPos = ref(0);   // Last win item position
    const isOpening = ref(false);   // Is case opening?
    const winItem = ref(null);  // Win item info
    const isWinInfoShown = ref(false);  // Is win info shown?

    let [interval1, interval2, interval3] = [null, null, null]; // Tick song intervals

    let interval = null;
    const MAX_IN_RULETTE = 256; // Max items in roullete spinner
    let ROULETTE_ITEM_WIDTH = (window.screen.width > 1366) ? 175 : 124;    // 175

    /** Load cases */
    function loadCases(callback = null) {
        $.post("/cases/load", {},
            function (data, textStatus, jqXHR) {
                console.log(data);

                if(data.error != null) {
                    notificationsStore.add('error', 'Error', data.error);
                    return;
                }

                cases.value = data.cases;
                canOpen.value = data.can_open;
                timeLeft.value = data.time_left;

                cases.value.forEach( (value, index) => {
                    value.color = getColor(value);
                    if( !canOpen.value && value.available ) value.color = '#B15420';
                } );

                initTimeLeftTimer();

                if(callback) callback();
            }
        );
    };

    // Load cases on popup opened
    onMounted(() => {
        let modal = document.querySelector('#modal-case');
        let observer = new MutationObserver(function (mutations) {
            mutations.forEach((value, index) => {
                if ( !value.target.classList.contains('mfp-hide') ) {
                    loadCases();
                } else {
                    resetSlide();
                }
            });
        });
        observer.observe(modal, {
            attributes: true
        });
    });

    /** Get case color */
    const getColor = (caseInfo) => {
        switch(caseInfo.available){
            case true:
                return '#20B16C';
            case false:
                return '#F05544';
            default:
                return '#F05544';
        }
    }

    /** View case */
    const viewCase = (id) => {
        $.post(`/cases/${id}`, {},
            function (data, textStatus, jqXHR) {
                console.log(data);

                if(data.error != null) {
                    notificationsStore.add('error', 'Error', data.error);
                    return;
                }
                viewedCase.value = data;

                canOpen.value = data.can_open.result;
                timeLeft.value = data.can_open.time_left;

                isCaseViewed.value = true;

                fillItems();
            },
        );


    }

    /** Init time left timer */
    const initTimeLeftTimer = () => {
        if(interval != null) return;

        interval = setInterval(function() {
            let timer = timeLeft.value.split(':');
            let hours = parseInt(timer[0], 10);
            let minutes = parseInt(timer[1], 10);
            let seconds = parseInt(timer[2], 10);
            --seconds;
            minutes = (seconds < 0) ? --minutes : minutes;
            hours = (minutes < 0) ? --hours : hours;
            if (hours < 0 && minutes < 0 && seconds <= 0 ) {
                clearInterval(interval);
                timeLeft.value = '00:00:00';
                canOpen.value = true;
                return;
            }
            seconds = (seconds < 0) ? 59 : seconds;
            seconds = (seconds < 10) ? '0' + seconds : seconds;

            minutes = (minutes < 0) ? 59 : minutes;
            minutes = (minutes < 10) ? '0' + minutes : minutes;

            hours = (hours < 10) ? '0' + hours : hours;

            timeLeft.value = hours + ':' + minutes + ':' + seconds;
        }, 1000);
    }

    /** Reset modal slide */
    const resetSlide = () => {
        loadCases( () => {
            isCaseViewed.value = false;
            isOpening.value = false;
            isWinInfoShown.value = false;
            winItem.value = null;
        } );
    }

    // Fill roulette items
    const fillItems = () => {
        itemsFilled.value = [];
        for (let i = 0; i < MAX_IN_RULETTE; i++) {
            let randIndex = Math.floor(Math.random() * viewedCase.value.items.length);
            itemsFilled.value.push(viewedCase.value.items[randIndex]);
        }
    };

    // Sort items by win
    const sortByWin = (item) => {
        let winIndex = findIndexById(item.item_id);

        itemsFilled.value[30] = viewedCase.value.items[winIndex];

        winItem.value = viewedCase.value.items[winIndex];

        let slicedItems = itemsFilled.value.slice(0, 40);
        slicedItems[30] = winItem.value;
        let lastWinItem = slicedItems.map(e => e.item.id).lastIndexOf(winItem.value.item.id);

        lastWinItemPos.value = lastWinItem;
    }

    /** Open case */
    const open = () => {
        if(isOpening.value) return;

        $.post(`/case/open`, {id: viewedCase.value.id},
            function (data, textStatus, jqXHR) {
                isOpening.value = true;

                startSong.play();

                if(data.error != null) {
                    notificationsStore.add('error', 'Error', data.error);
                    return;
                }

                sortByWin(data.win);

                nextTick( () => {

                    let rouletteWidth = document.querySelector('.case-roulette').offsetWidth;

                    let randomMove = Math.floor(Math.random() * (90 - 45 + 1)) + 45;
                    let marginLeft = `-${(lastWinItemPos.value * ROULETTE_ITEM_WIDTH) - (rouletteWidth / 2) + randomMove}`;

                    playTickSong();

                    $('.case-roulette__list').animate({
                        'margin-left': marginLeft
                    }, {
                        duration: 6000,
                        easing: 'swing',
                        /* step: ( now, fx ) => {
                            let pos = parseInt(now);
                        }, */
                        complete: () => {
                            setTimeout( () => {
                                clearInterval(interval3);

                                $('.case-roulette__list').animate({
                                    'margin-left': marginLeft - (90 - randomMove)
                                }, 300, 'swing', () => {
                                    $(`.case-roulette__item[data-index="${lastWinItemPos.value}"]`).css('margin-bottom', '60px');
                                    isWinInfoShown.value = true;

                                    tickSong.stop();
                                    resultSong.play();

                                    globalStore.user.inventory_price = data.inventory_price;
                                    globalStore.setInventory(data.inventory);
                                });
                            }, 300 );
                        }
                    });
                } );
            },
        );
    }

    /** Play tick song */
    const playTickSong = () => {
        interval1 = setInterval( () => {
            tickSong.play();
        }, 100 );

        setTimeout( () => {
            clearInterval(interval1);

            interval2 = setInterval( () => {
                tickSong.play();
            }, 200 );
        }, 2000 );

        setTimeout( () => {
            clearInterval(interval2);

            setTimeout( () => {
                tickSong.play();
            }, 200);

            interval3 = setInterval( () => {
                console.log('+');
                tickSong.play();
            }, 500 );
        }, 3000 );

        setTimeout( () => {
            clearInterval(interval3);
            setTimeout( () => {
                tickSong.play();
            }, 500 );
        }, 5500 );
    }

    /* Find item in list by id */
    const findIndexById = (id) => {
        for (let index in viewedCase.value.items) {
            if (viewedCase.value.items[index].item.id == id) return index;
        }

        return null;
    }
</script>

<template>
<!-- Modal ['Place Bet'] -->
<div id="modal-case" class="modal mfp-hide" style="width: 1171px; padding: 35px;">
    <transition mode="out-in">
        <div v-if="!isCaseViewed">
            <div class="modal__head">
                Free Daily Cases
                <div class="available-cases-info">
                    <img src="../../../../../images/icon-info.svg" alt="Info Icon" class="icon-info">
                    You can open all <span class="white">unlocked daily cases</span> every 24 hours.
                </div>
            </div>
            <div class="modal__body">
                <div class="cases">
                    <div class="case" v-for="(value, key) in cases" @click="viewCase(value.id)">
                        <p class="case__title">{{ value.name }}</p>
                        <img :src="value.image" :alt="value.name" class="case__image">
                        <div class="case__status">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8.15601" r="5" :fill="value.color" />
                                <circle cx="8" cy="8.15601" r="7.5" :stroke="value.color" />
                            </svg>

                            <svg width="208" height="248" viewBox="0 0 208 248" fill="none" xmlns="http://www.w3.org/2000/svg" class="case__mask">
                                <g opacity="0.3" filter="url(#filter0_f_3401_2009)">
                                    <ellipse cx="113.5" cy="309.156" rx="146.5" ry="135.5" :fill="value.color" />
                                </g>
                                <defs>
                                    <filter id="filter0_f_3401_2009" x="-205.8" y="0.856003" width="638.6" height="616.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="86.4" result="effect1_foregroundBlur_3401_2009" />
                                    </filter>
                                </defs>
                            </svg>

                            <span v-if="value.available && canOpen">Ready to <span class="strong">Open</span></span>
                            <span v-else-if="!value.available">Locked</span>
                            <span v-else>Ready in <span class="strong">{{ timeLeft }}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- If case is viewed -->
        <div v-else>
            <div class="modal__head">
                <button class="btn btn-back" @click="resetSlide" style="padding: 0;">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M18.5355 1.46447C17.0711 2.38419e-07 14.714 2.0621e-07 10 1.52795e-10C5.28595 -2.05905e-07 2.92893 -2.38419e-07 1.46447 1.46447C2.38419e-07 2.92893 2.06212e-07 5.28595 1.54159e-10 10C-2.05903e-07 14.714 -2.38419e-07 17.0711 1.46447 18.5355C2.92893 20 5.28595 20 10 20C14.714 20 17.0711 20 18.5355 18.5355C20 17.0711 20 14.714 20 10C20 5.28595 20 2.92893 18.5355 1.46447ZM12.0303 6.46967C12.3232 6.76256 12.3232 7.23744 12.0303 7.53033L9.56066 10L12.0303 12.4697C12.3232 12.7626 12.3232 13.2374 12.0303 13.5303C11.7374 13.8232 11.2626 13.8232 10.9697 13.5303L7.96967 10.5303C7.82902 10.3897 7.75 10.1989 7.75 10C7.75 9.80109 7.82902 9.61032 7.96967 9.46967L10.9697 6.46967C11.2626 6.17678 11.7374 6.17678 12.0303 6.46967Z"
                            fill="currentColor" fill-opacity="1" />
                    </svg>
                    Back
                </button>
            </div>
            <div class="modal__body" style="margin-top: 0;">
                <p class="case-title">{{ viewedCase.name }}</p>

                <div class="case-roulette">
                    <div class="case-roulette__arrow case-roulette__arrow_top"></div>
                    <div class="case-roulette__blur case-roulette__blur_left"></div>
                    <div class="case-roulette__list" :style="[{'margin-left': `${startLinePosition}px`}]">
                        <div class="case-roulette__item" :data-index="key" v-for="(value, key) in itemsFilled">
                            <img :src="value.item.image" :alt="value.item.name">
                            <div class="case-roulette__item-blur" :style="{'background-color': value.item.color}"></div>
                        </div>
                    </div>
                    <div class="case-roulette__arrow case-roulette__arrow_bottom"></div>
                    <div class="case-roulette__blur case-roulette__blur_right"></div>

                    <transition mode="out-in">
                        <div class="win-item-info" v-if="winItem != null && isWinInfoShown">
                            <div class="win-item-info__wrapper">
                                <p class="win-item-name"><span>{{ winItem.class }}</span> {{ winItem.item.name }}</p>
                                <span class="win-item-price" :style="{color: winItem.item.color}">${{ parseFloat(winItem.item.price_usd) }}</span>
                            </div>

                        </div>
                    </transition>
                </div>

                <transition mode="out-in">
                    <div v-if="isOpening">
                        <button id="open-case" class="btn case-opening">Opening</button>
                    </div>

                    <div v-else>
                        <button id="open-case" class="btn case-ready" v-if="viewedCase.available && canOpen" @click="open">Open case</button>
                        <button id="open-case" class="btn case-locked" v-else-if="!viewedCase.available"><img src="../../../../../images/icon-locked.svg"> Case locked</button>
                        <button id="open-case" class="btn case-waiting" v-else><span>Open in</span><span class="strong">{{ timeLeft }}</span></button>
                    </div>
                </transition>

                <p class="case-subtitle">Case contains</p>
                <div class="case-items">
                    <div class="shop-item" :data-price='value.item.price_usd' :data-drop_id="value.id" v-for="(value, key) in viewedCase.items" >
                        <div class="shop-item__body">
                            <span class="shop-item__price" :style="{color: value.item.color}">${{ parseFloat(value.item.price_usd) }}</span>
                            <div class="item-image">
                                <img :src="value.item.image" alt="" class="img-adaptive shop-item__avatar">
                                <div class="item-image__blur" :style="{'background-color': value.item.color}"></div>
                            </div>
                            <p class="shop-item__name"><span class="item-class">{{ value.class }}</span> {{ value.item.name }}</p>
                            <span class="shop-item__dropchance" :style="[{'color': value.item.color}, {'background': `${value.item.color}26`}]">{{ parseFloat(value.drop_chance) }}%</span>
                            <div class="blur" :style="[{'background': `linear-gradient(359deg, ${value.item.color} -292.06%, rgba(255, 0, 0, 0) 86.09%)`}, {'border-bottom': `3.694px solid ${value.item.color}`}]"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    </div>
</template>
