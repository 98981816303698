<script setup>
import { useGameStore } from '../../../stores/GameStore';

const gameStore = useGameStore();

/** Verify */
const verify = () => {
    $.post("/game/verify", {game_hash: gameStore.roundDetails.hash, game_id: gameStore.roundDetails.id},
        function (data, textStatus, jqXHR) {
            console.log(data);

            if(data.error != null){
                notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                return;
            }

            notificationsStore.add('success', 'Success', data.success);
        }
    );
}

</script>

<template>
    <div id="modal-verify" class="modal mfp-hide" style="width: 742px;">
        <div class="modal__head">Verify</div>
        <div class="modal__body" style="width: 632px;">
            <div class="input-group input-group_column w-100">
                <span class="input-label input-label_gray">Game ID</span>
                <input type="text" class="input-clear input-clear_grey w-100" placeholder="Enter Round ID here..." v-model="gameStore.roundDetails.id">
            </div>
            <div class="input-group input-group_column w-100">
                <span class="input-label input-label_gray">Round HASH</span>
                <input type="text" class="input-clear input-clear_grey w-100" placeholder="Enter Round HASH here..." v-model="gameStore.roundDetails.hash">
            </div>

            <button class="btn btn-ok" id="verify" @click="verify">Verify</button>
        </div>
    </div>
</template>
