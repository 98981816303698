<script setup>
    import { ref, onMounted } from 'vue';
    import ShopItemsSelected from './ShopItemsSelected.vue';
    import Items from '../Items.vue';
    import Dropdown from '../Dropdown.vue';
    import Modals from '../parts/Modals.vue';
    import { useWebSocketStore } from '../../stores/WebSocket';

    const globalStore = window.globalStore;
    const socketStore = useWebSocketStore();

    const props = defineProps(['items']);

    const items = ref(props.items); // Items list
    const searchName = ref('');    // Name to search

    let priceType = 'highest';   // From highest (Default Sorting)
    let priceRangeType = 'all';   // All (Default Sorting)

    const dropdownPriceRange = [
        {value: 'all', label: 'All'},
        {value: '0-5', label: '$0,00 - $5,00'},
        {value: '5-25', label: '$5,00 - $25,00'},
        {value: '25-50', label: '$25,00 - $50,00'},
        {value: '50-100', label: '$50,00 - $100,00'},
        {value: '100-250', label: '$100,00 - $250,00'},
        {value: '250', label: '$250,00+'}
    ];

    const dropdownSortings = [
        {value: 'highest', label: 'Highest price first'},
        {value: 'lowest', label: 'Lowest price first'},
    ];

    // Search items
    let waiting = false;
    const search = () => {
        if(waiting) return;
        waiting = true;
        setTimeout(_search, 300 );
    }

    // Sort by price
    const sortByPrice = (index) => {
       priceType = dropdownSortings[index].value;
        if(priceType == null) {
            notificationsStore.add('error', 'Error', `Can't identify dropdown's value`);
            return;
        }

        _search();
    }

    // Sort by price
    const sortByPriceRange = (index) => {
       priceRangeType = dropdownPriceRange[index].value;
        if(priceRangeType == null) {
            notificationsStore.add('error', 'Error', `Can't identify dropdown's value`);
            return;
        }

        _search();
    }

    function _search() {
        $.post("/shop/search", {name: searchName.value, price_type: priceType, price_range_type: priceRangeType},
            function (data, textStatus, jqXHR) {
                console.log(data);

                waiting = false;

                if(data.error != null){
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                    return;
                }

                items.value = data;
            }
        );
    }

    // Connect to websocket (If footer is not present)
    onMounted( () => {
        if( socketStore.socket != null ) return;
        let token = globalStore.user == null ? 'demo' : globalStore.user.token
        socketStore.connect(token);
    } );

</script>

<template>
    <div class="shop">
        <div class="shop__items">
            <div class="shop__items-manage">
                <div class="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                        <path
                            d="M14.875 15.375L10.6251 11.125M12.0417 7.58333C12.0417 10.3217 9.82175 12.5417 7.08333 12.5417C4.34492 12.5417 2.125 10.3217 2.125 7.58333C2.125 4.84492 4.34492 2.625 7.08333 2.625C9.82175 2.625 12.0417 4.84492 12.0417 7.58333Z"
                            stroke="white" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <input type="text" class="input-transparent" placeholder="Search" v-model="searchName" @input="search">
                </div>

                <div class="dropdowns">
                    <div class="dropdown-wrapper">
                        <span class="dropdown-label">Price range:</span>
                        <Dropdown :list="dropdownPriceRange" :index="1" :content_width="'199px'" :extra_style="{'color': '#fff'}" v-on:onSelect="sortByPriceRange"></Dropdown>
                    </div>

                    <Dropdown :list="dropdownSortings" :arrow_color="'grey'" :index="2" :content_width="'197px'" v-on:onSelect="sortByPrice"></Dropdown>
                </div>
            </div>

            <div class="shop__items-list">
                <!-- Items List -->
                <Items :items="items"></Items>
            </div>
        </div>

        <div class="shop__selected">
            <p class="label">Selected items</p>
            <!-- Selected Items -->
            <ShopItemsSelected></ShopItemsSelected>
        </div>
    </div>

    <Modals></Modals>
</template>
