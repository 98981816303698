<script setup>
    import { ref } from 'vue';
    import ModalBet from './modals/ModalBet.vue';
    import ModalDeposit from './modals/ModalDeposit.vue';
    import ModalPartnership from './modals/ModalPartnership.vue';
    import AdminModal from './modals/AdminModal.vue';
    import ModalVerify from './modals/ModalVerify.vue';
    import ModalHistory from './modals/ModalHistory.vue';
    import ModalCase from './modals/ModalCase.vue';

    const globalStore = window.globalStore;
</script>

<template>
    <!-- Modal ['Auth'] -->
    <div id="modal-signin" class="modal modal_small mfp-hide" style="width: 419px">
        <div class="modal__head modal__head_small text-center">Sign in</div>
        <div class="modal__body text-center">
            <!-- <img src="../../../../images/logo.svg" alt="Logo" class="img-adaptive logo-modal"> -->
            <img :src="globalStore.logo" alt="Logo" class="img-adaptive logo-modal">
            <a href="/steam/auth" class="link-clear">
                <button class="btn sign-in w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                        <path
                            d="M14.2078 9.8548C14.2078 8.58405 15.238 7.5538 16.5079 7.5538C17.7779 7.5538 18.8081 8.58405 18.8081 9.85399C18.8081 11.1247 17.7787 12.1542 16.5079 12.1542C16.5079 12.1542 16.5079 12.1542 16.5071 12.1542C15.2372 12.1542 14.2078 11.1247 14.2078 9.8548C14.2078 9.8548 14.2069 9.8548 14.2069 9.85399L14.2078 9.8548ZM19.5613 9.85967C19.5613 8.16886 18.1906 6.79817 16.4998 6.79817C14.809 6.79817 13.4383 8.16886 13.4383 9.85967C13.4383 11.5505 14.809 12.9212 16.4998 12.9212C18.1898 12.9187 19.5589 11.5489 19.5613 9.85967ZM7.97182 19.305L6.476 18.6859C6.90175 19.5617 7.78494 20.1549 8.80625 20.1549C9.87632 20.1549 10.7953 19.5041 11.1869 18.5762L11.1934 18.5591C11.3193 18.265 11.3933 17.9221 11.3933 17.563C11.3933 16.1354 10.2363 14.9784 8.80869 14.9784C8.47963 14.9784 8.16519 15.0402 7.87594 15.1515L7.89382 15.1458L9.44 15.7852C10.1323 16.081 10.6092 16.757 10.6092 17.5435C10.6092 18.5965 9.75525 19.4504 8.70225 19.4504C8.439 19.4504 8.18875 19.3968 7.96044 19.3009L7.97263 19.3058L7.97182 19.305ZM12.4788 0.815735C6.09494 0.816547 0.859191 5.72811 0.341629 11.9779L0.338379 12.0217L6.86925 14.7209C7.412 14.3455 8.08394 14.1221 8.80869 14.1221C8.8095 14.1221 8.81032 14.1221 8.81032 14.1221C8.8745 14.1221 8.93707 14.1261 9.00125 14.1285L11.9059 9.92305V9.86292C11.9084 7.32792 13.964 5.27392 16.499 5.27392C19.0356 5.27392 21.0921 7.33036 21.0921 9.86699C21.0921 12.4036 19.0356 14.4601 16.499 14.4601H16.3926L12.2537 17.4159C12.2537 17.4687 12.2578 17.5224 12.2578 17.5776C12.2578 17.5784 12.2578 17.5792 12.2578 17.58C12.2578 19.4829 10.7148 21.0259 8.81194 21.0259C7.14875 21.0259 5.76019 19.8469 5.43763 18.2796L5.43357 18.2577L0.757629 16.3215C2.25344 21.4768 6.93344 25.1818 12.4779 25.1818C19.2063 25.1818 24.6606 19.7275 24.6606 12.9992C24.6606 6.27086 19.2063 0.816547 12.4779 0.816547L12.4788 0.815735Z"
                            fill="white" />
                    </svg>
                    Sign in with Steam
                </button>
            </a>

            <p class="modal__label">
                By logging in, you confirm that you have read the <a href="/terms" class="link-clear link-white">Terms of
                    Service</a>, are you at least 18 years old.
            </p>
        </div>
    </div>

    <!-- Modal ['Deposit'] -->
    <ModalDeposit v-if="globalStore.user != null"></ModalDeposit>

    <!-- Modal ['Admin'] -->
    <AdminModal v-if="globalStore.user != null"></AdminModal>

    <!-- Modal ['Verify'] -->
    <ModalVerify></ModalVerify>

    <!-- Modal ['Game History'] -->
    <ModalHistory v-if="globalStore.user != null"></ModalHistory>

    <!-- Modal ['Partnership'] -->
    <ModalPartnership v-if="globalStore.user != null"></ModalPartnership>

    <!-- Modal ['Place Bet'] -->
    <ModalBet v-if="globalStore.user != null"></ModalBet>

    <!-- Modal ['Case'] -->
    <ModalCase v-if="globalStore.user != null"></ModalCase>
</template>
