<script setup>
import { ref, reactive, inject } from 'vue';

const globalStore = window.globalStore;

const props = defineProps(['added', 'total']);

const detailsVisible = ref(false);  // Are details visible?
const choosenSystem = ref(false);   // Choosen system
const exportDetails = ref('');  // Export details (Wallet / Trade link)
const items = ref( {
    added: props.added ?? {},
    totalSum: props.total ?? 0
});

console.log(items);

let filledHistory = '';

/** Available Systems */
const availableSystems = reactive({
    btc: {
        type: 'crypt',
        fullname: 'Bitcoin',
        shortname: 'BTC',
        rate_usd: globalStore.rate.BTC.price_usd ?? 0,
        image: new URL('/resources/assets/images/btc.svg', import.meta.url),

    },
    eth: {
        type: 'crypt',
        fullname: 'Ethereum',
        shortname: 'ETH',
        rate_usd: globalStore.rate.ETH.price_usd ?? 0,
        image: new URL('/resources/assets/images/eth.svg', import.meta.url),

    },
    ltc: {
        type: 'crypt',
        fullname: 'Litecoin',
        shortname: 'LTC',
        rate_usd: globalStore.rate.LTC.price_usd ?? 0,
        image: new URL('/resources/assets/images/ltc.svg', import.meta.url),

    },
    usdt_erc20: {
        type: 'crypt',
        fullname: 'USDT (ERC-20)',
        shortname: 'USDT',
        rate_usd: globalStore.rate.USDT.price_usd ?? 0,
        image: new URL('/resources/assets/images/usdt.svg', import.meta.url),

    },
    skins: {
        type: 'skins',
        fullname: 'CS:GO Skins',
        image: new URL('/resources/assets/images/cs-skins.svg', import.meta.url),

    },
});

/** Labels */
const labels = reactive({
    crypt: 'This action is irreversible. Please make sure the provided information is correct. Any additional network fees will be deducted from the cashout amount.',
    skins: 'Make sure that your profile and inventory are publicly available. If the inventory is private the withdrawal may not pass.'
})

/** History */
const history = reactive({
    btc: [
        { date: '10/23/2023 10:56 AM', amount: '$6,616.15', status: 'pending' },
        { date: '11/15/2023 10:32 AM', amount: '$2,616.15', status: 'declined' },
        { date: '12/14/2023 08:51 AM', amount: '$1,616.15', status: 'completed' },
        { date: '12/15/2023 09:58 AM', amount: '$10,616.15', status: 'completed' },
    ],
    eth: [
        { date: '10/15/2023 08:49 AM', amount: '$632', status: 'pending' },
        { date: '11/15/2023 10:51 AM', amount: '$9999', status: 'declined' },
    ],
    ltc: [
        { date: '10/14/2023 09:43 AM', amount: '$3,312.15', status: 'pending' },
        { date: '11/23/2023 09:55 AM', amount: '$1,545.15', status: 'declined' },
        { date: '12/15/2023 10:14 AM', amount: '$5,325.15', status: 'completed' },
        { date: '12/17/2023 10:51 AM', amount: '$4,653.15', status: 'completed' },
    ],
    usdt_erc20: [
        { date: '10/15/2023 07:51 AM', amount: '$3,543.15', status: 'pending' },
        { date: '11/08/2023 09:32 AM', amount: '$616.15', status: 'declined' },
        { date: '12/12/2023 09:43 AM', amount: '$436.15', status: 'completed' },
        { date: '12/24/2023 10:15 AM', amount: '$14,876.15', status: 'completed' },
    ],
    skins: [
        { date: '10/04/2023 10:32 AM', amount: '$3,123.15', status: 'pending' },
        { date: '11/11/2023 10:45 AM', amount: '$616.15', status: 'declined' },
        { date: '12/23/2023 10:57 AM', amount: '$436.15', status: 'completed' },
    ],
});

/** Get systems by type */
const getSystemsByType = (type = 'crypt') => {
    return Object.fromEntries(
        Object.entries(availableSystems).filter(([key, value]) => value.type == type)
    );
};

/** Fill history */
const fillHistory = (key) => {
    let records = history[key];
    console.log(records);
    let content = '';

    const statuses = {
        pending: `<div class="status status_pending">
                            <img src="${new URL('/resources/assets/images/pending.svg', import.meta.url)}" alt="" class="img-adaptive status__icon">
                            Pending
                        </div>`,
        completed: `<div class="status status_completed">
                            <img src="${new URL('/resources/assets/images/ok.svg', import.meta.url)}" alt="" class="img-adaptive status__icon">
                            Complete
                        </div>`,
        declined: `<div class="status status_declined">
                            <img src="${new URL('/resources/assets/images/declined.svg', import.meta.url)}" alt="" class="img-adaptive status__icon">
                            Declined
                        </div>`
    };

    if (records) {

        records.forEach((item) => {
            content += `
                    <tr class="align-left">
                        <td class="td-grey">${item.date}</td>
                        <td>${item.amount}</td>
                        <td>${statuses[item.status]}</td>
                    </tr>
                    <tr class="empty">
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>`;
        });

        filledHistory = content;
    } else {
        filledHistory = '';
    }
};

/** Toggle details by payment type */
const toggle = (key) => {
    if (detailsVisible.value) return;

    fillHistory(key);
    choosenSystem.value = availableSystems[key];
    detailsVisible.value = true;
};

/** Request withdrawal */
const requestWithdrawal = () => {
    if( Object.keys(items.value.added).length < 1){
        notificationsStore.add('error', 'Error', `Nothing selected`);
        return;
    }

    if( exportDetails.value.length < 1){
        if( choosenSystem.value.type == 'crypt' ) {
            notificationsStore.add('error', 'Error', 'You need to set address');
        } else {
            notificationsStore.add('error', 'Error', 'You need to set trade link');
        }
        return;
    }

    let drops = getDrops();

    $.post("/items/export", {drops},
        function (data, textStatus, jqXHR) {
            console.log(data);

            if(data.error != null){
                notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
            } else if(data.success != null){
                notificationsStore.add('success', 'Success', data.success);
                //$cookies.remove('items_export');

                items.value = {
                    added: {},
                    totalSum: 0
                };

            }
        }
    );
}

///////////////////////////////////////////
/**
 * SPECIAL FUNCTIONS
 */

// Get drops
function getDrops(){
    let drops = [];
    for (const [key, value] of Object.entries(items.value.added)) {
        drops.push(value.drop_id);
    }

    return drops;
}

</script>

<template>
    <transition mode="out-in">
        <div class="box" id="withdraw" v-if="!detailsVisible">
            <h1 class="h1 text-center">Withdraw</h1>

            <div class="payment-group">
                <p class="payment-group__name">Cryptocurrency</p>
                <div class="payments">
                    <div class="payment" v-for="(value, key) in getSystemsByType('crypt')" @click="toggle(key)">
                        <img :src="value.image" alt="" class="img-adaptive">
                        <div class="payment__details">
                            <p class="payment__name">{{ value.fullname }}</p>
                            <span class="payment__rate">1 {{ value.shortname }} = ~${{ value.rate_usd }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="payment-group">
                <p class="payment-group__name">Skins</p>
                <div class="payments">
                    <div class="payment" v-for="(value, key) in getSystemsByType('skins')" @click="toggle(key)">
                        <img :src="value.image" alt="" class="img-adaptive">
                        <div class="payment__details">
                            <p class="payment__name">{{ value.fullname }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="payment-choosen" v-else>
            <!-- Payment Cashout Details -->
            <div class="box cashout">

                <button class="btn btn-back" @click="detailsVisible = false">
                    <!-- <img src="../../../../../images/icon-back.svg" alt="" class="img-adaptive"> -->
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M18.5355 1.46447C17.0711 2.38419e-07 14.714 2.0621e-07 10 1.52795e-10C5.28595 -2.05905e-07 2.92893 -2.38419e-07 1.46447 1.46447C2.38419e-07 2.92893 2.06212e-07 5.28595 1.54159e-10 10C-2.05903e-07 14.714 -2.38419e-07 17.0711 1.46447 18.5355C2.92893 20 5.28595 20 10 20C14.714 20 17.0711 20 18.5355 18.5355C20 17.0711 20 14.714 20 10C20 5.28595 20 2.92893 18.5355 1.46447ZM12.0303 6.46967C12.3232 6.76256 12.3232 7.23744 12.0303 7.53033L9.56066 10L12.0303 12.4697C12.3232 12.7626 12.3232 13.2374 12.0303 13.5303C11.7374 13.8232 11.2626 13.8232 10.9697 13.5303L7.96967 10.5303C7.82902 10.3897 7.75 10.1989 7.75 10C7.75 9.80109 7.82902 9.61032 7.96967 9.46967L10.9697 6.46967C11.2626 6.17678 11.7374 6.17678 12.0303 6.46967Z"
                            fill="currentColor" fill-opacity="1" />
                    </svg>
                    Back
                </button>

                <img :src="choosenSystem.image" alt="" class="img-adaptive cashout__icon">
                <p class="cashout__name">{{ choosenSystem.fullname }} Withdraw</p>
                <span class="cashout__rate" v-if="choosenSystem.type == 'crypt'">1 {{ choosenSystem.shortname }} = ${{
                    choosenSystem.rate_usd }} </span>

                <div class="cashout-note">
                    <div class="border"></div>
                    <p>{{ labels[choosenSystem.type] }}</p>
                </div>

                <div class="input-group input-group_column w-100" style="margin-top: 0;">
                    <span v-if="choosenSystem.type == 'crypt'" class="input-label">{{ choosenSystem.fullname }}
                        address</span>
                    <span v-else class="input-label">Trade Link</span>

                    <input v-if="choosenSystem.type == 'crypt'" type="text" class="input-clear input-clear_lightgrey w-100"
                        :placeholder="'Paste your ' + choosenSystem.fullname + ' address...'" v-model="exportDetails">
                    <input v-else type="text" class="input-clear input-clear_lightgrey w-100"
                        placeholder="Paste your trade link..." v-model="exportDetails">
                </div>

                <button class="btn btn-gradient w-100" id="cashout" @click="requestWithdrawal">Request withdrawal {{ Object.keys(items.added).length ?? 0 }} items ${{ items.totalSum.toFixed(2) ?? 0 }}</button>
            </div>

            <!-- Payment Cashout History -->
            <div class="box" v-if="globalStore.user != null && globalStore.user.role == 1">
                <div class="box__title">{{ choosenSystem.shortname }} Withdrawal History</div>
                <div class="table-responsive" style="padding-top: 36px;">
                    <table class="table table-rows_grey">
                        <thead>
                            <tr>
                                <th style="width: 300px;">Date</th>
                                <th class="text-left">Amount</th>
                                <th class="text-left" style="min-width: 105px; width: 105px;">Status</th>
                            </tr>
                        </thead>
                        <tbody v-html="filledHistory">
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
</transition></template>
