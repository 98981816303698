<script setup>
    import { ref } from 'vue';
    import { useNotificationsStore } from '../../stores/NotificationsStore';

    const notificationsStore = useNotificationsStore();
</script>

<template>
    <div class="notifications">
        <transition-group>
            <div class="notification-wrapper" :key="key" v-for="(value, key) in notificationsStore.added">
                <div class="notification notification_error" :class="['notification_' + value.type]">
                    <p class="notification__title">{{ value.title }}</p>
                    <span v-html="value.message"></span>
                    <div class="close" @click="notificationsStore.remove(key)"></div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
