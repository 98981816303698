import { defineStore } from 'pinia';
import { helpers } from '../../helpers';
import { useGameStore } from './GameStore';

export const useWebSocketStore = defineStore('WebSocketStore', {

    state: () => ({
        socket: null,
        gameStore: useGameStore()
    }),
    actions: {
        connect(token){
            //this.socket = new WebSocket('wss://skinpot.gg/wss2/?token='+token);
            this.socket = new WebSocket(window.SOCKET_ADDRESS + '/?token=' + token);
            this.socket.onopen = (e) => {
                console.log("Socket connection established..");
                setInterval(this.heartBeat, 5000);
            };

            this.socket.onmessage = (e) => {
                //console.log(e.data);
                let message = JSON.parse(e.data);
                this.watchMessage(message);
            };

            this.socket.onerror = function(e) {
                console.log(e);
                //notificationsStore.add('error', 'Error', e);
            };
        },
        watchMessage(message){
            if(message.action == null) {
                console.log('No message callback found.');
                return;
            }

            console.log(message);

            switch(message.action){
                // Balance changed
                case 'change_balance':
                    if(message.level != null) globalStore.user.level = message.level;

                    if( message.new_balance != null ) {
                        globalStore.user.balance = message.new_balance;
                        //notificationsStore.add('success', 'Success', 'Your balance was changed');
                    }

                    if( message.inventory_price != null ) {
                        globalStore.user.inventory_price = message.inventory_price;
                        //notificationsStore.add('success', 'Success', 'Your balance was changed');
                    }
                    break;
                // Payment approved
                case 'payment_approved':
                    if( message.new_balance != null ) {
                        globalStore.user.balance = message.new_balance;
                        notificationsStore.add('success', 'Deposit', `Your $${message.incremented_balance ?? 0} deposit is now credited on your balance.`);
                    }
                    break;
                // Load game
                case 'load_game':
                    if( message.game != null ) {
                        this.gameStore.loadGame(message.game);
                        if( message.history != null ) this.gameStore.loadHistory(message);
                    } else {
                        notificationsStore.add('error', 'Error', 'Error occured during loading game. Refresh page');
                    }
                    break;
                // Added bid
                case 'added_bid':
                    if( message.bid != null ) {
                        this.gameStore.displayBid(message.bid);
                    } else {
                        notificationsStore.add('error', 'Error', 'Error occured during loading bids. Refresh page');
                    }
                    break;
                // Notify
                case 'notify':
                    if(message.type == null || message.message == null) return;
                    let messageTitle = message.type == 'error' ? 'Error' : 'Success';
                    notificationsStore.add(message.type, message.title ?? messageTitle, message.message);
                    break;
            }
        },
        heartBeat() {
            this.socket.send( helpers.prepareMessage({
                action: 'ping'
            }) );
        }
    },
});
