<script setup>
    import { helpers } from '../../../helpers';
    import { useGameStore } from '../../stores/GameStore';

    const gameStore = useGameStore();

    const setRoundDetails = (hash, id) => {
        gameStore.roundDetails.hash = hash;
        gameStore.roundDetails.id = id;
    }

    //console.log(gameStore.winsHistory);

</script>

<template>
    <!-- BOX -->
    <transition-group name="slide">
        <div class="box" :key="key" v-for="(value, key) in gameStore.winsHistory">
            <div class="winner-last" :style="{ 'background': value.bg_color }">
                {{ value.winner_info }} won the pot valued at ${{ value.winSum.toFixed(2) }} with a chance of {{ value.winner.win_percentage.toFixed(2) }}%
            </div>

            <div class="win-items">
                <div class="win-item" :key="k"
                        v-for="(v, k) in value.bids" :data-user="v.user">
                        <div class="win-item__avatar" @click="gameStore.toggleWinnerMode(v.user)" :style="[ v.border_color != null ? {border: `2px solid ${v.border_color}`, 'box-shadow': `0 0 13px 0 ${v.border_color}45`} : '']">
                            <transition>
                                <div class="win-management-buttons" v-if="gameStore.toggledWinner == v.user">
                                    <button class="btn" style="background-color: #3a5042;" @click="gameStore.copySteam(v.user)">Copy Steam</button>
                                    <button class="btn" style="background-color: #503a3a; width: 70px;" @click="gameStore.addLevel(v.user)">+1 LVL</button>
                                </div>
                            </transition>
                            <img :src="v.avatar" alt="" class="img-adaptive">
                        </div>
                        <div class="win-item__details">
                            <div class="win-item__account">
                                <div class="win-item__account-level">{{ v.level }}</div>
                                <span class="win-item__account-login">{{ v.name }}</span>
                            </div>
                            <div class="win-item__total">
                                <!-- <img src="../../../../images/icon-site_coins.svg" alt="" class="img-adaptive icon-worth_coins"> -->
                                <span class="win-item__worth">Deposited: <span class="white" :style="{/* color: value.line_color */}">${{
                                    v.skins_price.toFixed(2) }}</span></span>
                            </div>
                        </div>
                        <div class="win-item__percentage" :style="[ v.border_color != null ? { border: `2px dashed ${v.border_color}`, 'background': `${v.bg_color}` } : '']"><span>{{ value.percentages[v.user] }}%</span></div>
                    </div>
            </div>

            <div class="hash">
                <span>GAME ID #{{ value.game.id }}</span>
                <span>Round hash: {{ value.game.game_hash }}</span>
                <button class="btn hash__verified" @click="() => { setRoundDetails(value.game.game_hash, value.game.id); helpers.openPopup('#modal-verify') }">
                    <img src="../../../../images/ok.svg" alt="" class="img-adaptive">
                    Verify
                </button>
            </div>
        </div>
    </transition-group>
</template>
