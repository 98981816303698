<script setup>
    import { ref } from 'vue';

    const globalStore = window.globalStore;

    const refCode = ref( globalStore.user == null ? '' : (globalStore.user.referal_code ?? '') );

    /** Update ref code */
    const updateRefCode = () => {
        $.post("/profile/update_ref", {referal_code: refCode.value},
            function (data, textStatus, jqXHR) {
                console.log(data);

                if(data.error != null){
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                    return;
                }

                notificationsStore.add('success', 'Success', data.success);
            }
        );
    }
</script>

<template>
    <!-- Modal ['Partnership'] -->
    <div id="modal-partnership" class="modal mfp-hide" style="width: 895px;">
        <div class="modal__head">Partnership</div>
        <div class="modal__body">
            <div class="partnership">
                <div class="partnership__left">
                    <div class="partnership__code">
                        <p class="label">Create affiliate code</p>
                        <p class="sublabel">Create your own code to earn 10% of the deposits</p>
                        <div class="input-group">
                            <input type="text" class="input-clear" placeholder="Set up your code here…" v-model="refCode">
                            <button class="btn btn-ok btn-ok_outline" @click="updateRefCode"><span>Set up</span></button>
                        </div>
                    </div>

                    <div class="partnership__stats">
                        <p class="label">Statistic</p>
                        <div class="partnership__stats-list">
                            <div class="partnership__stats-list-users partnership__stats-list-item">
                                <div class="partnership__stats-icon">
                                    <img src="../../../../../images/icon-user_big.svg" alt="" class="img-adaptive">
                                </div>
                                <div>
                                    <p class="partnership__stats-title">Users</p>
                                    <span class="partnership__stats-amount">{{ globalStore.user.referals.total_users }}</span>
                                </div>

                            </div>

                            <div class="partnership__stats-list-earning partnership__stats-list-item">
                                <div class="partnership__stats-icon">
                                    <img src="../../../../../images/icon-tag_big.svg" alt="" class="img-adaptive">
                                </div>
                                <div>
                                    <p class="partnership__stats-title">Total earnings</p>
                                    <span class="partnership__stats-amount">${{ globalStore.user.referals.total_sum.toFixed(2) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="partnership__users">
                    <div class="partnership__users-table partnership__users-table_left">
                        <p class="partnership__users-table-title">Users</p>
                        <div class="partnership__users-table-body">
                            <span v-for="(value, index) in globalStore.user.referals.list">{{ value.name }}</span>
                        </div>
                    </div>
                    <div class="partnership__users-table">
                        <p class="partnership__users-table-title">Deposited</p>
                        <div class="partnership__users-table-body">
                            <span v-for="(value, index) in globalStore.user.referals.list">${{ parseFloat(value.total_bonus) }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
