import { defineStore } from 'pinia';

export const useModalStore = defineStore('ModalStore', {
    state: () => ({
        resetState: true
    }),
    actions: {
        // Add notification to list
        reset(){
            this.resetState = true;
        }
    },
});
