//import HackTimer from 'hacktimer';

import.meta.glob([
    '../images/**',
]);

/** Songs [START] */
import {Howl, Howler} from 'howler';

window.tickSong = new Howl({
    src: ['/sounds/tick.wav'],
    //loop: true
});

window.startSong = new Howl({
    src: ['/sounds/case_start.wav']
});

window.resultSong = new Howl({
    src: ['/sounds/case_end_sound_money.wav']
});

window.okSong = new Howl({
    src: ['/sounds/notif-success.wav']
});

window.errorSong = new Howl({
    src: ['/sounds/notif-error.wav']
});
/** Songs [END] */

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueCookies from 'vue-cookies';

import '../scss/styles.scss';
import 'animate.css';

import $ from 'jquery';
window.jQuery = window.$ = $;

import { helpers } from './helpers.js';
window.helpers = helpers;

import 'magnific-popup';
import './jquery.easing.min.js';

const app = createApp({});
const pinia = createPinia();
app.use(pinia);

app.use(VueCookies, { expires: '7d'});

// Set CSRF token
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('[name="_token"]').val(),
    }
});

// Global user store
import { useGlobalStore } from './core/stores/GlobalStore.js';
window.globalStore = useGlobalStore();

// Notification store for global use
import { useNotificationsStore } from './core/stores/NotificationsStore.js';
window.notificationsStore = useNotificationsStore();

// Modal store for global use
import { useModalStore } from './core/stores/ModalStore.js';
window.modalStore = useModalStore();

import Header from './core/components/parts/Header.vue';
import Footer from './core/components/parts/Footer.vue';
import Injective from './core/components/Injective.vue';
app.component('header-part', Header);
app.component('footer-part', Footer);
app.component('injective', Injective);

// Index Page
import GamesList from './core/components/index/Games.vue';
import Game from './core/components/index/Game.vue';
if( document.querySelector('games-list') ) {
    app.component('games-list', GamesList);
}
if( document.querySelector('game') ) {
    app.component('game', Game);
}

// FAQ Page
import FAQIndex from './core/components/faq/FAQIndex.vue';
if( document.querySelector('faq-items') ) {
    app.component('faq-items', FAQIndex);
}

// Shop Page
import Shop from './core/components/shop/Shop.vue';
if( document.querySelector('shop') ) {
    app.component('shop', Shop);
}

// Withdraw Page
import Withdraw from './core/components/withdraw/WithdrawIndex.vue';
if( document.querySelector('withdraw') ) {
    app.component('withdraw', Withdraw);
}

// Profile Page
import ProfileInventory from './core/components/profile/ProfileInventory.vue';
if( document.querySelector('profile') ) {
    app.component('profile', ProfileInventory);
}

app.mount('#app');

// Close dropdowns on click outside
import { useDropdownStore } from './core/stores/DropdownStore.js';
const dropdownStore = useDropdownStore();
window.addEventListener('click', ({ target }) => {
    if( typeof target.className !== 'string' ) return;
    if(target.className == null || !target.className.includes('dropdown') ) dropdownStore.change(false);
});
