<script setup>
    import { nextTick, ref, reactive, inject } from 'vue';
    import Items from '../Items.vue';
    import Dropdown from '../Dropdown.vue';
    import { useShopItemsStore } from '../../stores/ShopItemsStore';

    const props = defineProps(['drops']);

    // Create items from drops to pass into Items.vue
    const items = ref([]);
    props.drops.forEach( (value, index) => {
        value.item.drop_id = value.id;
        items.value[index] = value.item;
    });

    const shopItemsStore = useShopItemsStore();

    const itemsComponentRef = ref(null);    // Items Component Object Ref

    const dropdownSortings = [
        {value: 'highest', label: 'Highest price first'},
        {value: 'lowest', label: 'Lowest price first'},
    ];

    let waiting = false;

    // Sort by price
    const sortByPrice = (index) => {
        if(waiting) return;
        waiting = true;

        let priceType = dropdownSortings[index].value;
        if(priceType == null) {
            waiting = false;
            notificationsStore.add('error', 'Error', `Can't identify dropdown's value`);
            return;
        }

        $.post("/inventory/sort", {price_type: priceType},
            function (data, textStatus, jqXHR) {
                console.log(data);

                waiting = false;

                if(data.error != null){
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                    return;
                }

                updateDrops(data);
            }
        );
    }

    // Sell
    const sell = () => {
        if(waiting) return;
        waiting = true;

        if( shopItemsStore.added.size < 1 ){
            waiting = false;
            notificationsStore.add('error', 'Error', `Nothing selected`);
            return;
        }

        let drops = getDrops();

        $.post("/items/sell", {drops},
            function (data, textStatus, jqXHR) {
                console.log(data);
                waiting = false;

                if(data.error != null){
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                } else if(data.success != null){
                    shopItemsStore.added.forEach( (value, key) => {
                        delete items.value[key];
                    } );

                    items.value = items.value.filter( (value, key) => value != null );

                    console.log(items.value);
                    console.log(shopItemsStore.added);

                    shopItemsStore.addedItems.clear();
                    shopItemsStore.totalSum = 0;
                    globalStore.user.balance = data.balance;
                    globalStore.user.inventory_price = data.inventory_price;

                    notificationsStore.add('success', 'Success', data.success);
                }
            }
        );
    };

    /** Prepare to export */
    const prepareToExport = () => {
        if( shopItemsStore.added.size < 1 ){
            waiting = false;
            notificationsStore.add('error', 'Error', `Nothing selected`);
            return;
        }

        let items = [];
        shopItemsStore.added.forEach( (value, key) => items.push(value.drop_id) );

        $.post("/export/prepare", {drops: items},
            function (data, textStatus, jqXHR) {
                console.log(data);
                if(data.error != null) {
                    notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                    return;
                }

                window.location.href = '/withdraw';
            }
        );
    }

    /////////////////////////////////////////////////////
    /**
     * SPECIAL FUNCTIONS
     */

    // Get drops
    function getDrops(){
        let items = [];
        shopItemsStore.added.forEach( (value, key) => {
            items.push(value.drop_id);
        } );
        return items;
    }

    // Update drops list
    function updateDrops(data){
        //Object.assign(items, {});
        items.value = [];
        data.forEach( (value, index) => {
            value.item.drop_id = value.id;
            items.value[index] = value.item;
        });
    }
</script>

<template>
    <div class="inventory">
        <p class="label">Inventory</p>
        <div class="inventory__manage" :class="{'inventory__manage_disabled': drops.length == 0}">
            <label class="checkbox-container" id="select-all">Select all
                <input type="checkbox" @click="itemsComponentRef.chooseAll()">
                <span class="checkmark"></span>
            </label>
            <Dropdown :list="dropdownSortings" :extra_content_style="{'right': 'auto'}" :arrow_color="'grey'" :index="2" :content_width="'197px'" v-on:onSelect="sortByPrice"></Dropdown>

            <button class="btn btn-transparent btn-inventory" id="inventory-sell" @click="sell">
                <img src="../../../../images/icon-deposit-total.svg" alt="" class="img-adaptive">
                Sell all {{ shopItemsStore.added.size }} skins for ${{ shopItemsStore.totalSum.toFixed(2) }}
            </button>
            <button class="btn btn-transparent btn-inventory" id="inventory-withdraw" @click="prepareToExport">
                <img src="../../../../images/icon-withdraw.svg" alt="" class="img-adaptive">
                Withdraw {{ shopItemsStore.added.size }} skins for ${{ shopItemsStore.totalSum.toFixed(2) }}
            </button>

            <div class="inventory__manage-disabled" v-if="drops.length == 0"></div>
        </div>

        <div class="inventory__border"></div>

        <div class="items" v-if="drops.length > 0">
            <Items ref="itemsComponentRef" :items="items"></Items>
        </div>

        <div class="no-items" v-else>
            <p class="no-items__title">Your inventory is empty!</p>
            <span class="no-items__label">You can buy items into Skin Shop.</span>
        </div>
    </div>
</template>
