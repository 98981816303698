import { defineStore } from 'pinia';

export const useNotificationsStore = defineStore('NotificationsStore', {
    state: () => ({
        added: []
    }),
    actions: {
        // Add notification to list
        add(type, title, message){
            if(type == 'error') {
                okSong.play()
            } else {
                errorSong.play();
            }
            this.added.push({type, title, message});
            setTimeout( () => {
                //this.added.splice(this.added.length-1, 1);

                this.added.pop();
                console.log(this.added);
            }, 4000);
        },
        // Remove notification
        remove(index){
            this.added.splice(index, 1);
        }
    },
});
