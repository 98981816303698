<script setup>
import { ref, onMounted } from 'vue';
import { helpers } from '../../../helpers';
import Messages from './Messages.vue';
import { useWebSocketStore } from '../../stores/WebSocket';

const globalStore = window.globalStore;
const notificationsStore = window.notificationsStore;
const socketStore = useWebSocketStore();

const menuToggled = ref(false);
const accountToggled = ref(false);

const toggleNav = () => {
    menuToggled.value = !menuToggled.value;
}

onMounted(() => {
    window.addEventListener('click', ({ target }) => {
        if (accountToggled.value && target.parentElement.closest('.dropdown-custom') == null) accountToggled.value = false;
    });

    // Connect to websocket
    let token = globalStore.user == null ? 'demo' : globalStore.user.token
    socketStore.connect(token);
});

// Show admin bets modal
const showAdminModal = () => {
    if( globalStore.user != null && globalStore.user.role == 1 ) {
        helpers.openPopup('#modal-admin', 'mfp-fadein');
    }
}

</script>

<template>
    <!-- Header -->
    <header class="header" :class="[menuToggled ? 'toggled' : '']">
        <!-- Logo -->
        <a href="/">
            <img :src="globalStore.logo" alt="Logo" class="img-adaptive logo">
        </a>

        <div id="burger" @click="toggleNav" :class="[menuToggled ? 'active' : '']">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <!-- Navigation -->
        <ul class="nav ul-inline" :style="[menuToggled ? { 'display': 'inherit' } : '']">
            <li>
                <a href="/daily_race" class="nav__link">
                    <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.75 0C1.16421 0 1.5 0.335786 1.5 0.75V2.6L3.22067 2.25587C4.8712 1.92576 6.58211 2.08284 8.14493 2.70797L8.55821 2.87329C9.98309 3.44323 11.5513 3.54967 13.0401 3.17746C13.6711 3.01972 14.1778 3.7036 13.8432 4.26132L12.5647 6.39221C12.2232 6.96137 12.0524 7.24595 12.0119 7.55549C11.9951 7.68461 11.9951 7.81539 12.0119 7.94451C12.0524 8.25405 12.2232 8.53863 12.5647 9.10779L14.1253 11.7089C14.4361 12.2269 14.1582 12.898 13.5721 13.0445L13.472 13.0695C11.7024 13.5119 9.83848 13.3854 8.14493 12.708C6.58211 12.0828 4.8712 11.9258 3.22067 12.2559L1.5 12.6V20.75C1.5 21.1642 1.16421 21.5 0.75 21.5C0.335786 21.5 0 21.1642 0 20.75V0.75C0 0.335786 0.335786 0 0.75 0Z"
                            fill="currentColor" fill-opacity="1" />
                    </svg>

                    Daily race
                </a>
            </li>
            <li>
                <a href="#" class="nav__link" @click="() => {
                    globalStore.user != null ? helpers.openPopup('#modal-partnership') : notificationsStore.add('error', 'Error', 'You need to be authed to perform this action');
                }">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M14.6976 1.3587C14.1613 2.10609 13.3954 3.03 12.9956 3.41217C12.0241 4.34065 10.1082 5.19087 8.54736 5.38609L7.27444 5.54522V8.91304V12.2809L8.54736 12.44C9.98814 12.6202 11.7275 13.3517 12.7262 14.1974C13.097 14.5115 13.7778 15.3341 14.2388 16.0257C15.4876 17.8983 15.8585 18.2609 16.5243 18.2609C17.6024 18.2609 17.6975 17.5215 17.6975 9.13043C17.6975 2.53826 17.6528 1.53174 17.3286 0.815217C17.0229 0.139783 16.8494 0 16.3163 0C15.7758 0 15.5167 0.217391 14.6976 1.3587ZM0.572401 6.22522C0.032355 6.76587 0 6.91804 0 8.91304C0 10.908 0.032355 11.0602 0.572401 11.6009C1.13177 12.1607 1.20973 12.1739 3.93819 12.1739H6.73158V8.91304V5.65217H3.93819C1.20973 5.65217 1.13177 5.66543 0.572401 6.22522ZM18.4575 9.34783C18.4575 10.5639 18.5435 11.3043 18.6845 11.3043C18.8093 11.3043 19.1513 11.0641 19.4445 10.7707C20.1852 10.0291 20.1852 8.66652 19.4445 7.925C19.1513 7.63152 18.8093 7.3913 18.6845 7.3913C18.5435 7.3913 18.4575 8.13174 18.4575 9.34783ZM2.02838 13.3696C2.08592 13.6087 2.39101 14.9783 2.70609 16.413C3.48848 19.9741 3.50954 20 5.6074 20C6.63125 20 7.39865 19.8974 7.55674 19.7391C7.99125 19.3041 7.86617 17.947 7.32873 17.2633C6.932 16.7585 6.82582 16.3057 6.76415 14.8557L6.68815 13.0698L4.30582 13.0024C2.01687 12.9374 1.92762 12.9517 2.02838 13.3696Z"
                            fill="currentColor" fill-opacity="1" />
                    </svg>
                    Partnership
                </a>
            </li>
            <li>
                <a href="/shop" class="nav__link">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.99976 0.25C7.30136 0.25 6.69851 0.659118 6.4178 1.25077C5.73426 1.25574 5.20152 1.28712 4.72597 1.47298C4.15778 1.69505 3.66357 2.07255 3.29985 2.5623C2.93292 3.05639 2.76067 3.68968 2.5236 4.56133L2.47721 4.73169L1.96448 7.69473C1.77895 7.82272 1.61781 7.97428 1.47767 8.15376C0.576839 9.30753 1.00581 11.0234 1.86376 14.4552C2.40943 16.6379 2.68227 17.7292 3.49605 18.3646C4.30983 19 5.43476 19 7.68462 19H12.3153C14.5652 19 15.6901 19 16.5039 18.3646C17.3176 17.7292 17.5905 16.6379 18.1362 14.4552C18.9941 11.0234 19.4231 9.30753 18.5222 8.15376C18.382 7.97414 18.2207 7.82247 18.035 7.69442L17.5223 4.73169L17.4759 4.56133C17.2388 3.68968 17.0666 3.05639 16.6997 2.5623C16.336 2.07255 15.8417 1.69505 15.2736 1.47298C14.798 1.28712 14.2653 1.25574 13.5817 1.25077C13.301 0.659118 12.6982 0.25 11.9998 0.25H7.99976ZM16.4177 7.14571L16.0564 5.05765C15.7726 4.01794 15.6696 3.69121 15.4954 3.45663C15.2996 3.19291 15.0335 2.98964 14.7275 2.87007C14.5077 2.78416 14.2421 2.75888 13.5803 2.75219C13.299 3.34225 12.697 3.75 11.9998 3.75H7.99976C7.30252 3.75 6.70052 3.34225 6.41921 2.75219C5.75738 2.75888 5.4918 2.78416 5.272 2.87007C4.96605 2.98964 4.69994 3.19291 4.50409 3.45662C4.32988 3.6912 4.22688 4.01794 3.9431 5.05765L3.58176 7.14577C4.57992 7 5.9096 7 7.68462 7H12.3153C14.0901 7 15.4196 7 16.4177 7.14571ZM6 10.25C6.41421 10.25 6.75 10.5858 6.75 11V15C6.75 15.4142 6.41421 15.75 6 15.75C5.58579 15.75 5.25 15.4142 5.25 15V11C5.25 10.5858 5.58579 10.25 6 10.25ZM14.75 11C14.75 10.5858 14.4142 10.25 14 10.25C13.5858 10.25 13.25 10.5858 13.25 11V15C13.25 15.4142 13.5858 15.75 14 15.75C14.4142 15.75 14.75 15.4142 14.75 15V11ZM10 10.25C10.4142 10.25 10.75 10.5858 10.75 11V15C10.75 15.4142 10.4142 15.75 10 15.75C9.58579 15.75 9.25 15.4142 9.25 15V11C9.25 10.5858 9.58579 10.25 10 10.25Z"
                            fill="currentColor" fill-opacity="1" />
                    </svg>

                    Skin Shop
                </a>
            </li>
        </ul>

        <!-- Admin Menu -->
<!--         <button v-if="globalStore.user != null && globalStore.user.role == 1" class="btn" id="admin-menu"
            @click="helpers.openPopup('#modal-admin')">
            <img src="../../../../images/admin-menu.svg" alt="" class="img-adaptive">
        </button> -->

        <!-- Management -->
        <div class="header__management" :style="[menuToggled ? { 'display': 'inherit' } : '']">
            <div class="header__balance">
                <button v-if="globalStore.user != null" class="btn" id="free-case"
                    @click="helpers.openPopup('#modal-case')">Free case</button>

                <button v-if="globalStore.user != null" class="btn" id="add-deposit"
                    @click="helpers.openPopup('#modal-deposit')">Deposit<!-- <img src="../../../../images/icon-add_deposit.svg"
                        alt="" class="img-adaptive"> --></button>

                <div v-if="globalStore.user != null" class="balances">
                    <div class="header__balance-amount header__balance-amount_site" @click="showAdminModal">
                        <img src="../../../../images/icon-site_balance.svg" alt="" class="img-adaptive">
                        ${{ parseFloat(globalStore.user.balance).toFixed(2) }}
                    </div>
                    <a href="/profile" class="link-clear">
                        <div class="header__balance-amount header__balance-amount_coins">
                        <img src="../../../../images/icon-site_coins.svg" alt="" class="img-adaptive">
                        ${{ parseFloat(globalStore.user.inventory_price).toFixed(2) }}
                    </div>
                    </a>

                </div>

                <div class="account">
                    <!-- Sign in -->
                    <button class="btn sign-in" id="sign-in" @click="helpers.openPopup('#modal-signin')"
                        v-if="globalStore.user == null">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                            <path
                                d="M14.2078 9.8548C14.2078 8.58405 15.238 7.5538 16.5079 7.5538C17.7779 7.5538 18.8081 8.58405 18.8081 9.85399C18.8081 11.1247 17.7787 12.1542 16.5079 12.1542C16.5079 12.1542 16.5079 12.1542 16.5071 12.1542C15.2372 12.1542 14.2078 11.1247 14.2078 9.8548C14.2078 9.8548 14.2069 9.8548 14.2069 9.85399L14.2078 9.8548ZM19.5613 9.85967C19.5613 8.16886 18.1906 6.79817 16.4998 6.79817C14.809 6.79817 13.4383 8.16886 13.4383 9.85967C13.4383 11.5505 14.809 12.9212 16.4998 12.9212C18.1898 12.9187 19.5589 11.5489 19.5613 9.85967ZM7.97182 19.305L6.476 18.6859C6.90175 19.5617 7.78494 20.1549 8.80625 20.1549C9.87632 20.1549 10.7953 19.5041 11.1869 18.5762L11.1934 18.5591C11.3193 18.265 11.3933 17.9221 11.3933 17.563C11.3933 16.1354 10.2363 14.9784 8.80869 14.9784C8.47963 14.9784 8.16519 15.0402 7.87594 15.1515L7.89382 15.1458L9.44 15.7852C10.1323 16.081 10.6092 16.757 10.6092 17.5435C10.6092 18.5965 9.75525 19.4504 8.70225 19.4504C8.439 19.4504 8.18875 19.3968 7.96044 19.3009L7.97263 19.3058L7.97182 19.305ZM12.4788 0.815735C6.09494 0.816547 0.859191 5.72811 0.341629 11.9779L0.338379 12.0217L6.86925 14.7209C7.412 14.3455 8.08394 14.1221 8.80869 14.1221C8.8095 14.1221 8.81032 14.1221 8.81032 14.1221C8.8745 14.1221 8.93707 14.1261 9.00125 14.1285L11.9059 9.92305V9.86292C11.9084 7.32792 13.964 5.27392 16.499 5.27392C19.0356 5.27392 21.0921 7.33036 21.0921 9.86699C21.0921 12.4036 19.0356 14.4601 16.499 14.4601H16.3926L12.2537 17.4159C12.2537 17.4687 12.2578 17.5224 12.2578 17.5776C12.2578 17.5784 12.2578 17.5792 12.2578 17.58C12.2578 19.4829 10.7148 21.0259 8.81194 21.0259C7.14875 21.0259 5.76019 19.8469 5.43763 18.2796L5.43357 18.2577L0.757629 16.3215C2.25344 21.4768 6.93344 25.1818 12.4779 25.1818C19.2063 25.1818 24.6606 19.7275 24.6606 12.9992C24.6606 6.27086 19.2063 0.816547 12.4779 0.816547L12.4788 0.815735Z"
                                fill="white" />
                        </svg>
                        Sign in
                    </button>
                    <!-- Signed In Account -->
                    <div v-else class="dropdown-custom" id="account-dropdown" :class="accountToggled ? 'opened' : ''">
                        <div class="account__authed" @click="accountToggled = !accountToggled">
                            <img v-if="globalStore.user.avatar == null" src="../../../../images/avatar_small.png" alt=""
                                class="img-adaptive avatar-small">
                            <img v-else :src="globalStore.user.avatar" alt="" class="img-adaptive avatar-small">
                            <div class="user">
                                <div class="user__level">{{ globalStore.user.level }}</div>
                                <span class="user__login">{{ globalStore.user.name }}</span>
                            </div>
                        </div>
                        <transition>
                            <div class="account__navigation" v-if="accountToggled">
                                <ul class="ul-clear">
                                    <li><a href="/profile"><svg width="15" height="19" viewBox="0 0 15 19" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7.4001" cy="4.6999" r="3.8" fill="currentColor"
                                                    fill-opacity="1" />
                                                <ellipse cx="7.4" cy="15.1501" rx="6.65" ry="3.8" fill="currentColor"
                                                    fill-opacity="1" />
                                            </svg>
                                            Profile</a></li>
                                    <li><a href="#" @click="helpers.openPopup('#modal-partnership')"><svg width="20"
                                                height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M14.6976 1.3587C14.1613 2.10609 13.3954 3.03 12.9956 3.41217C12.0241 4.34065 10.1082 5.19087 8.54736 5.38609L7.27444 5.54522V8.91304V12.2809L8.54736 12.44C9.98814 12.6202 11.7275 13.3517 12.7262 14.1974C13.097 14.5115 13.7778 15.3341 14.2388 16.0257C15.4876 17.8983 15.8585 18.2609 16.5243 18.2609C17.6024 18.2609 17.6975 17.5215 17.6975 9.13043C17.6975 2.53826 17.6528 1.53174 17.3286 0.815217C17.0229 0.139783 16.8494 0 16.3163 0C15.7758 0 15.5167 0.217391 14.6976 1.3587ZM0.572401 6.22522C0.032355 6.76587 0 6.91804 0 8.91304C0 10.908 0.032355 11.0602 0.572401 11.6009C1.13177 12.1607 1.20973 12.1739 3.93819 12.1739H6.73158V8.91304V5.65217H3.93819C1.20973 5.65217 1.13177 5.66543 0.572401 6.22522ZM18.4575 9.34783C18.4575 10.5639 18.5435 11.3043 18.6845 11.3043C18.8093 11.3043 19.1513 11.0641 19.4445 10.7707C20.1852 10.0291 20.1852 8.66652 19.4445 7.925C19.1513 7.63152 18.8093 7.3913 18.6845 7.3913C18.5435 7.3913 18.4575 8.13174 18.4575 9.34783ZM2.02838 13.3696C2.08592 13.6087 2.39101 14.9783 2.70609 16.413C3.48848 19.9741 3.50954 20 5.6074 20C6.63125 20 7.39865 19.8974 7.55674 19.7391C7.99125 19.3041 7.86617 17.947 7.32873 17.2633C6.932 16.7585 6.82582 16.3057 6.76415 14.8557L6.68815 13.0698L4.30582 13.0024C2.01687 12.9374 1.92762 12.9517 2.02838 13.3696Z"
                                                    fill="currentColor" fill-opacity="1" />
                                            </svg>
                                            Partnership</a></li>
                                    <li><a href="#" @click="helpers.openPopup('#modal-case')"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.45353 11.9975V16.4158C2.45594 16.6379 2.54586 16.8501 2.70375 17.0062C2.86165 17.1624 3.07478 17.25 3.29686 17.25H8.44853V11.9975H2.45353ZM9.95186 17.25H15.1035C15.3256 17.25 15.5387 17.1624 15.6966 17.0062C15.8545 16.8501 15.9444 16.6379 15.9469 16.4158V11.9975H9.95186V17.25ZM16.6252 4.49917H14.9385C15.1113 4.14828 15.2022 3.76277 15.2044 3.37167C15.2019 2.67552 14.9237 2.00871 14.4306 1.51731C13.9375 1.02591 13.2697 0.749996 12.5735 0.75C11.8463 0.784489 11.1436 1.024 10.5467 1.44086C9.94981 1.85772 9.48299 2.43494 9.2002 3.10583C8.9174 2.43494 8.45058 1.85772 7.85367 1.44086C7.25676 1.024 6.55411 0.784489 5.82686 0.75C5.13071 0.749996 4.46293 1.02591 3.96982 1.51731C3.4767 2.00871 3.19845 2.67552 3.19603 3.37167C3.1982 3.76277 3.2891 4.14828 3.46186 4.49917H1.7752C1.31686 4.49917 0.950195 5.04 0.950195 5.7V9.3025C0.950195 9.9625 1.31686 10.5033 1.7752 10.5033H8.44853V4.49917H9.95186V10.5033H16.6252C17.0835 10.5033 17.4502 9.9625 17.4502 9.3025V5.7C17.4502 5.04 17.0835 4.49917 16.6252 4.49917ZM5.82686 4.49917C5.67087 4.51328 5.51365 4.49473 5.36523 4.44471C5.2168 4.39469 5.08043 4.31429 4.96479 4.20865C4.84916 4.103 4.75681 3.97442 4.69362 3.8311C4.63044 3.68779 4.5978 3.53288 4.5978 3.37625C4.5978 3.21962 4.63044 3.06471 4.69362 2.9214C4.75681 2.77808 4.84916 2.6495 4.96479 2.54385C5.08043 2.43821 5.2168 2.35781 5.36523 2.30779C5.51365 2.25777 5.67087 2.23923 5.82686 2.25333C7.1102 2.25333 7.83436 3.57333 8.19186 4.49917H5.82686ZM12.5735 4.49917H10.2085C10.566 3.5825 11.2902 2.25333 12.5735 2.25333C12.7295 2.23923 12.8867 2.25777 13.0352 2.30779C13.1836 2.35781 13.32 2.43821 13.4356 2.54385C13.5512 2.6495 13.6436 2.77808 13.7068 2.9214C13.77 3.06471 13.8026 3.21962 13.8026 3.37625C13.8026 3.53288 13.77 3.68779 13.7068 3.8311C13.6436 3.97442 13.5512 4.103 13.4356 4.20865C13.32 4.31429 13.1836 4.39469 13.0352 4.44471C12.8867 4.49473 12.7295 4.51328 12.5735 4.49917Z" fill="currentColor" fill-opacity="1"/>
                                        </svg>
                                        Free case</a></li>
                                    <li><a href="#" @click="helpers.openPopup('#modal-game_history')"><svg width="18"
                                                height="20" viewBox="0 0 18 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M1.96309 2.0129C0.850098 3.12589 0.850098 4.91723 0.850098 8.4999V12.2999C0.850098 15.8826 0.850098 17.6739 1.96309 18.7869C3.07609 19.8999 4.86742 19.8999 8.4501 19.8999H10.3501C13.9328 19.8999 15.7241 19.8999 16.8371 18.7869C17.9501 17.6739 17.9501 15.8826 17.9501 12.2999V8.4999C17.9501 4.91723 17.9501 3.12589 16.8371 2.0129C15.7241 0.899902 13.9328 0.899902 10.3501 0.899902H8.4501C4.86742 0.899902 3.07609 0.899902 1.96309 2.0129ZM4.8876 6.5999C4.8876 6.2064 5.20659 5.8874 5.6001 5.8874H13.2001C13.5936 5.8874 13.9126 6.2064 13.9126 6.5999C13.9126 6.99341 13.5936 7.3124 13.2001 7.3124H5.6001C5.20659 7.3124 4.8876 6.99341 4.8876 6.5999ZM4.8876 10.3999C4.8876 10.0064 5.20659 9.6874 5.6001 9.6874H13.2001C13.5936 9.6874 13.9126 10.0064 13.9126 10.3999C13.9126 10.7934 13.5936 11.1124 13.2001 11.1124H5.6001C5.20659 11.1124 4.8876 10.7934 4.8876 10.3999ZM5.6001 13.4874C5.20659 13.4874 4.8876 13.8064 4.8876 14.1999C4.8876 14.5934 5.20659 14.9124 5.6001 14.9124H10.3501C10.7436 14.9124 11.0626 14.5934 11.0626 14.1999C11.0626 13.8064 10.7436 13.4874 10.3501 13.4874H5.6001Z"
                                                    fill="currentColor" fill-opacity="1" />
                                            </svg>
                                            Game history</a></li>
                                    <li><a href="/logout"><svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.543701 10.3999C0.543701 10.7934 0.862698 11.1124 1.2562 11.1124L11.6801 11.1124L9.81751 12.7089C9.51874 12.965 9.48414 13.4148 9.74023 13.7136C9.99632 14.0124 10.4461 14.047 10.7449 13.7909L14.0699 10.9409C14.2278 10.8055 14.3187 10.6079 14.3187 10.3999C14.3187 10.1919 14.2278 9.99429 14.0699 9.85893L10.7449 7.00893C10.4461 6.75284 9.99632 6.78744 9.74023 7.08621C9.48414 7.38498 9.51874 7.83478 9.81751 8.09087L11.6801 9.6874L1.2562 9.6874C0.862698 9.6874 0.543701 10.0064 0.543701 10.3999Z"
                                                    fill="currentColor" fill-opacity="1" />
                                                <path
                                                    d="M7.9062 8.26244L8.26557 8.26244C8.01995 7.56553 8.14284 6.76023 8.65829 6.15888C9.42655 5.26257 10.776 5.15876 11.6723 5.92703L14.9973 8.77703C15.471 9.18312 15.7437 9.77595 15.7437 10.3999C15.7437 11.0239 15.471 11.6168 14.9973 12.0229L11.6723 14.8729C10.776 15.6411 9.42655 15.5373 8.65829 14.641C8.14285 14.0397 8.01995 13.2344 8.26557 12.5374L7.9062 12.5374L7.9062 14.1999C7.9062 16.8869 7.9062 18.2304 8.74095 19.0652C9.57569 19.8999 10.9192 19.8999 13.6062 19.8999L14.5562 19.8999C17.2432 19.8999 18.5867 19.8999 19.4215 19.0652C20.2562 18.2304 20.2562 16.8869 20.2562 14.1999L20.2562 6.5999C20.2562 3.91291 20.2562 2.56939 19.4215 1.73465C18.5867 0.899902 17.2432 0.899902 14.5562 0.899902L13.6062 0.899902C10.9192 0.899902 9.57569 0.899902 8.74095 1.73465C7.9062 2.56939 7.9062 3.9129 7.9062 6.5999L7.9062 8.26244Z"
                                                    fill="currentColor" fill-opacity="1" />
                                            </svg>
                                            Sign out</a></li>
                                </ul>
                            </div>
                        </transition>

                    </div>

                </div>
            </div>
        </div>
</header>

<messages></messages>
</template>
