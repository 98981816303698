<script setup>
    import { ref, toValue, toRaw, onMounted, nextTick } from 'vue';
    import { helpers } from '../../../../helpers.js';
    import { useGameStore } from '../../../stores/GameStore';
    import { useWebSocketStore } from '../../../stores/WebSocket';

    const gameStore = useGameStore();
    const webSocketStore = useWebSocketStore();

    const MAX_ITEMS_PER_BET = 15;

    const globalStore = window.globalStore;

    const addedItems = ref( new Map() );    // Added items
    const totalSum = ref(0);  // Total price of added item
    const searchName = ref('');    // Name to searchs

    // Add / remove item
    const toggle = (index, event) => {
        if( addedItems.value.has(index) ){
            totalSum.value -= parseFloat(addedItems.value.get(index).price);
            if(totalSum.value < 0) totalSum.value = 0;

            addedItems.value.delete(index);
        } else {

            let tempTotalCount = addedItems.value.size + 1;

            if(tempTotalCount > MAX_ITEMS_PER_BET) {
                notificationsStore.add('error', 'Error', `You can select only ${MAX_ITEMS_PER_BET} items`);
                return;
            }

            let item = {
                key: index,
                image: globalStore.inventory[index].item.image,
                price: globalStore.inventory[index].item.price_usd,
                /* type: inventory[index].item.type, */
                avatar: globalStore.user.avatar,
                name: globalStore.user.name,
                level: globalStore.user.level,
                item_name: globalStore.inventory[index].item.name,
                color: globalStore.inventory[index].item.color,
                drop_id: globalStore.inventory[index].id
            };

            addedItems.value.set(index, item);
            totalSum.value += parseFloat(item.price);
        }
    }

    // Place bet
    const placeBet = () => {
        if(addedItems.value.size == 0){
            notificationsStore.add('error', 'Error', 'You must select an item');
            return;
        }

        if(addedItems.value.size > MAX_ITEMS_PER_BET){
            notificationsStore.add('error', 'Error', `You can select only ${MAX_ITEMS_PER_BET} items`);
            return;
        }

        if( parseFloat(totalSum.value) < 1 ) {
            notificationsStore.add('error', 'Error', 'Min bet: $1');
            return;
        }

        let drops = [];
        addedItems.value.forEach( (value, key) => drops.push(value.drop_id) );

        if(webSocketStore.socket == null) {
            notificationsStore.add('error', 'Error', 'You are not connected to websocket. Refresh page!');
            addedItems.value.clear();
            return;
        }

        webSocketStore.socket.send( helpers.prepareMessage({
            action: 'place_bet',
            game_id: gameStore.game.game.id,
            drops
        }) );

        /** On success message after bet sent to websocket */
        /* webSocketStore.socket.addEventListener("message", function(event) {
            let answer = JSON.parse(event.data);
            // If bid was successfully confirmed (Second listener)
            if(answer.action == 'bid_confirmed'){
                notificationsStore.add('success', answer.title ?? 'Success', answer.bid.success);

                gameStore.add(globalStore.user.id, new Map( toRaw(addedItems.value) ), totalSum.value);
                globalStore.setInventory(answer.bid.inventory);
                globalStore.user.inventory_price = answer.bid.inventory_price;

                addedItems.value.clear();
                totalSum.value = 0;

                helpers.closePopup();
            // If notified (Second listener)
            } else if(answer.action == 'notify') {
                if(answer.type == 'error') {
                    addedItems.value.clear();
                    totalSum.value = 0;
                }
            }
        }); */
    }

    /** Search item inside inventory */
    let waiting = false;
    const search = () => {
        if(waiting) return;
        waiting = true;
        setTimeout(() => {
            $.post("/inventory/search", {name: searchName.value},
                function (data, textStatus, jqXHR) {
                    console.log(data);

                    waiting = false;

                    if(data.error != null){
                        notificationsStore.add('error', 'Error', helpers.parseError(data.error) );
                        return;
                    }

                    globalStore.setInventory(data);
                }
            );
        }, 300 );
    }

    // Reset Popup Logic on close
    onMounted(() => {

        console.log(webSocketStore.socket);

        /** On success message after bet sent to websocket */
        webSocketStore.socket.addEventListener("message", function(event) {
            let answer = JSON.parse(event.data);
            // If bid was successfully confirmed (Second listener)
            if(answer.action == 'bid_confirmed'){
                notificationsStore.add('success', answer.title ?? 'Success', answer.bid.success);

                gameStore.add(globalStore.user.id, new Map( toRaw(addedItems.value) ), totalSum.value);
                globalStore.setInventory(answer.bid.inventory);
                globalStore.user.inventory_price = answer.bid.inventory_price;

                addedItems.value.clear();
                totalSum.value = 0;

                helpers.closePopup();
            // If notified (Second listener)
            } else if(answer.action == 'notify') {
                if(answer.type == 'error') {
                    addedItems.value.clear();
                    totalSum.value = 0;
                }
            }
        });

        let modal = document.querySelector('#modal-place_bet');
        let observer = new MutationObserver(function (mutations) {
            mutations.forEach((value, index) => {
                if (value.target.classList.contains('mfp-hide')) {
                    addedItems.value = new Map();
                    totalSum.value = 0;
                }
            });
        });
        observer.observe(modal, {
            attributes: true
        });
    });

</script>

<template>
    <!-- Modal ['Place Bet'] -->
    <div id="modal-place_bet" class="modal mfp-hide" style="width: 1043px;">
        <div class="modal__head">Place bet</div>
        <div class="modal__body">
            <div class="skins">
                <div class="skins__info">
                    <div class="badges">
                        <div class="badge badge_blue">
                            <img src="../../../../../images/icon-site_coins.svg" alt="" class="img-adaptive">
                            Max {{ MAX_ITEMS_PER_BET }} items
                        </div>
                        <div class="badge badge_pink">Min bet $1</div>
                    </div>

                    <div class="search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                            <path
                                d="M14.875 15.375L10.6251 11.125M12.0417 7.58333C12.0417 10.3217 9.82175 12.5417 7.08333 12.5417C4.34492 12.5417 2.125 10.3217 2.125 7.58333C2.125 4.84492 4.34492 2.625 7.08333 2.625C9.82175 2.625 12.0417 4.84492 12.0417 7.58333Z"
                                stroke="white" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        <input type="text" class="input-transparent" placeholder="Search" v-model="searchName" @input="search">
                    </div>
                </div>

                <div class="items" v-if="globalStore.inventory != null && Object.keys(globalStore.inventory).length > 0">
                    <div class="shop-item shop-item_default" @click="toggle(key, $event)" :class="[addedItems.has(key) ? 'active' : '']" :data-price='value.item.price_usd' :data-drop_id="value.id" v-for="(value, key) in globalStore.inventory" >
                        <div class="shop-item__body">
                            <span class="shop-item__price" :style="{color: value.item.color}">${{value.item.price_usd}}</span>
                            <img :src="value.item.image" alt="" class="img-adaptive shop-item__avatar">
                            <p class="shop-item__name">{{ value.item.name }}</p>
                            <div class="blur"  :style="[{'background': `linear-gradient(359deg, ${value.item.color} -292.06%, rgba(255, 0, 0, 0) 86.09%)`}, {'border-bottom': `3.694px solid ${value.item.color}`}]"></div>
                        </div>
                    </div>
                </div>

                <div class="no-items" style="margin: 63px auto 45px;" v-else>
                    <p class="no-items__title">Your inventory is empty!</p>
                    <span class="no-items__label">You can buy items into Skin Shop.</span>
                </div>

                <div class="skins__stats" v-if="globalStore.inventory != null && Object.keys(globalStore.inventory).length > 0">
                    <span>Total selected: <span class="skins__stats-value">{{ addedItems.size }} items</span></span>
                    <span>Total amount: <span class="skins__stats-value">${{ totalSum.toFixed(2) }}</span></span>
                    <span>Inventory worth: <span class="skins__stats-value">${{ globalStore.user.inventory_price }}</span></span>
                </div>

                <div class="skins__actions" v-if="globalStore.inventory != null && Object.keys(globalStore.inventory).length > 0">
                    <button class="btn btn-orange" @click="helpers.closePopup()">Cancel</button>
                    <button class="btn btn-gradient" @click="placeBet">Place</button>
                </div>

                <div class="skins__actions" v-else>
                    <button class="btn btn-orange" @click="helpers.closePopup()">Cancel</button>
                    <a href="/shop" class="link-clear"><button class="btn btn-gradient">Skin shop</button></a>
                </div>
            </div>

        </div>
    </div>
</template>
